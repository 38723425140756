$background-color: #fff;
$border-color: #e5eaf5;

.root {
    display: flex;
    width: 32px;
    height: 32px;
    align-items: center;
    justify-content: center;
    border: 1px solid $border-color;
    background-color: $background-color;
    border-radius: 10px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.05);
    cursor: pointer;

    &:hover {
        background: darken($background-color, 5%);

        .icon {
            transform: rotate(360deg);
            transition: all 0.5s ease;
        }
    }

    &:disabled {
        background: darken($background-color, 10%);

        .icon {
            transform: rotate(0deg);
        }
    }
}

.icon {
    display: flex;
    min-width: 9px;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease;
}
