.root {
    position: relative;
    display: flex;
    flex-direction: column;
}

.error,
.inputName {
    margin-bottom: 6px;
    margin-left: 16px;
    color: #303e65;
    font-size: 13px;
    font-weight: 600;
}

.error {
    margin-top: 8px;
    color: #eb5757;
}

.fieldHeader {
    display: flex;
    justify-content: space-between;
}

.inputWarning {
    position: relative;
    margin: 0;
    color: #008b9c;
    font-size: 13px;
    font-weight: 600;
    line-height: 15px;
}

.input {
    width: 100%;
    min-height: 100px;
    padding: 16px 21px;
    border: 0;
    background-color: #f8faff;
    border-radius: 15px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.05), inset 0 5px 20px rgba(0, 0, 0, 0.03);
    color: #303e65;
    font-size: 14px;
    font-weight: 500;
    outline: none;

    &::placeholder {
        color: #008b9c;
        font-weight: 400;
    }

    &:focus {
        box-shadow: 2px 1px 9px 2px rgba(74, 82, 87, 0.18);
    }

    &:disabled {
        background-color: #e9e9e9;
    }
}

.require {
    color: #eb5757;
    font-size: 15px;
}

.default {
    color: #008b9c;
}

.errorColor {
    color: #eb5757;
}
