.root {
    display: flex;
    flex-direction: column;
}

.textField {
    max-width: unset !important;
}

.btnSubmit {
    margin-left: auto;
}
