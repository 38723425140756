@import "../../../../mixin";

.root {
    width: calc(100% - 82px);
}

.miniHeader {

    @include mobile {
        display: flex;
        flex-direction: column;
        padding: 10px;
        gap: 10px;
    }
}

.subHeader {
    display: flex;
    width: 100%;
    min-height: 62px;
    align-items: center;
    justify-content: space-between;
    padding: 0 48px 0 38px;
    background: #f8faff;
}

.userCounter {
    color: #303e65;
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
}

.state {
    color: #303e65;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
}

.btnToolTip {
    display: flex;
    justify-content: flex-end;
}

.btnRemove {
    color: #eb5757;
}

.email {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.name {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
}
