@import "../../../../mixin";

.root {
    width: calc(100% - 282px);

    @include tablet {
        width: calc(100% - 82px);
    }
}

.subHeader {
    display: flex;
    width: 100%;
    min-height: 62px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 10px 48px 10px 38px;
    background: #f8faff;

    @include mobile {
        padding: 10px;
    }

    @include media-custom(350) {
        justify-content: center;
    }
}

.headerContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;

    @include mobile {
        flex-direction: column;
        align-items: center;
        padding: 10px;
        gap: 10px;
    }
}

.header {
    display: flex;

    @include media-custom(1250) {
        flex-direction: column;
    }
}

.toggleContainer {
    display: flex;
    align-items: center;

    @include media-custom(442) {
        flex-direction: column;
    }
}

.toggleLeftTitle {
    margin-right: 12px;
    color: #008b9c;
    font-size: 0.75em;
    text-transform: uppercase;

    @include media-custom(442) {
        margin-right: 0;
    }
}

.toggleRightTitle {
    margin-left: 12px;
    color: #008b9c;
    font-size: 0.75em;
    text-transform: uppercase;

    @include media-custom(442) {
        margin-left: 0;
    }
}

.searchContainer {
    display: flex;
    align-items: center;
    margin-left: 190px;

    @include media-custom(1450) {
        margin-left: 90px;
    }

    @include media-custom(1333) {
        margin-left: 0;
    }

    @include mobile {
        flex-direction: column;
        align-items: center;
    }
}

.filterBtn {
    display: flex;
    width: 150px;
    align-items: center;
    justify-content: center;
    margin-left: 10px;

    @include mobile {
        width: 100%;
        margin-top: 10px;
    }
}

.tableTitleName {
    padding-left: 20px;
}

.companyNameContainer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding-right: 10%;
    padding-left: 20px;
}

.company,
.school,
.registrationDate,
.parental {
    font-size: 0.85em;
}

.name {
    margin-left: 20px;
    color: #303e65;
    font-weight: 600;
}

.avatar {
    width: 32px;
    height: 32px;
    border: 1px solid #e5eaf5;
    border-radius: 50%;
}

.defaultAvatar {
    display: flex;
    width: 32px;
    height: 32px;
    align-items: center;
    justify-content: center;
    background-color: #e5eaf5;
    border-radius: 50%;

    img {
        width: 14px;
        height: 14px;
    }
}

.optionBtnCell {
    display: flex;
    justify-content: space-between;
    padding-right: 20px;
}

.statusYes {
    color: #285f74;
}

.statusNo {
    color: #ef8400;
}

.selectCompany {
    width: 246px;
    border: 1px solid #e5eaf5;
    margin-right: 15px;
    margin-left: 15px;
    border-radius: 10px;

    :global {
        .select__control {
            border: 0;
            background-color: transparent;

            &:hover {
                cursor: pointer;

                svg {
                    :global {
                        animation: rotate360 0.8s linear;
                    }
                }
            }
        }

        .select__control--is-focused {
            border-radius: 10px;
        }

        .select__placeholder {
            padding-left: 10px;
        }

        .select__option {
            padding: 15px;
            background: none;
            border-radius: 15px;
            color: #008b9c;
            cursor: pointer;

            span {
                color: #008b9c;
            }
        }

        .select__value-container {
            height: 38px;
            padding: 0;
        }

        .select__indicator-separator {
            display: none;
        }

        .select__option--is-focused {
            background-color: #f8faff;
        }

        .select__option--is-selected {
            border-radius: 0;
        }
    }

    &Option {
        display: flex;
        justify-content: flex-start;

        &Icon {
            margin-top: 3px;
            margin-left: 10px;
        }

        &Text {
            margin-left: 10px;

            h3 {
                margin: 0;
                font-size: 0.85em;
                font-weight: 500;
            }

            p {
                margin: 0;
                color: #008b9c;
                font-size: 0.65em;
            }
        }
    }
}

.userAvatar {
    img {
        object-fit: cover;
    }
}

.warning {
    max-width: 25px;
    height: 25px;
    margin-right: 5px;
    margin-left: auto;
    border-radius: 50%;
}

.parentalWrapper {
    display: flex;
    align-items: center;
}

.checked {
    max-width: 20px;
    height: 20px;
    margin-right: 5px;
    margin-left: auto;
    border-radius: 50%;
}

.statusContainer {
    display: flex;
    min-width: 150px;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
}

.status {
    font-size: 13px;
}
