@import "../../../../mixin";

.root {
    width: calc(100% - 282px);

    @include tablet {
        width: calc(100% - 82px);
    }
}

.header {

    @include mobile {
        display: flex;
        flex-direction: column;
        padding: 10px;
        gap: 10px;
    }
}

.tableContainer {
    min-width: 100%;
}

.addExpertBtn {
    width: 200px;
    height: 48px;
}

.nameText {
    margin-left: 20px;
    color: #303e65;
    font-size: 14px;
    font-weight: 600;
}

.branchContainer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.deleteBtn {
    margin-left: 10px;
}

.pending {
    color: #ef8400;
}

.accepted {
    color: #285f74;
}
