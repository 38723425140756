@import "../../../../mixin";

.root {
    width: calc(100% - 282px);

    @include tablet {
        width: calc(100% - 82px);
    }
}

.table {
    &TitleDocumentName {
        padding-left: 20px;
    }

    &NameContainer {
        display: flex;
        min-width: 250px;
        align-items: center;
        padding-left: 20px;
    }
}

.type {
    min-width: 200px;
}

.employee {
    min-width: 150px;
}

.documentName,
.type,
.employee,
.date {
    font-size: 0.85em;
}

.documentName {
    margin-left: 10px;
    color: #303e65;
    font-size: 0.88em;
    font-weight: 600;
}

.dateContainer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
}
