@import "../../../../mixin";

.root {
    width: calc(100% - 282px);

    @include tablet {
        width: calc(100% - 82px);
    }
}

.subHeader {
    display: flex;
    width: 100%;
    min-height: 62px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 10px 48px 10px 38px;
    background: #f8faff;

    @include mobile {
        padding: 10px;
    }

    @include media-custom(350) {
        justify-content: center;
    }
}

.userCounter {
    width: 120px;
    color: #303e65;
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
}

.downloadContent {
    display: flex;
    width: auto;
    align-items: center;
    color: #303e65;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;

    @include media-custom(600) {
        display: block;
    }
}

.miniHeader {

    @include mobile {
        flex-direction: column;
        align-items: center;
        padding: 10px;
    }

    &Content {
        display: flex;
        align-items: center;

        @include mobile {
            flex-direction: column;
        }
    }
}

.filterBtn {
    display: flex;
    justify-content: center;

    @include mobile {
        width: 100% !important;
    }
}

.rootLoading {
    transform: rotate(0deg);

    :global {
        animation: rotate360 0.5s linear infinite;
    }
}

.search {
    width: 100%;
    max-width: 440px;
    margin: 0 10px;

    @include mobile {
        margin: 10px;
    }
}

.empty {
    min-width: 12px;
    max-width: 12px;
    min-height: 12px;
    max-height: 12px;
    border: 2px solid #008b9c;
    margin: 0 5px;
    border-radius: 2px;
}

.txt {
    position: relative;
    max-width: 12px;
    margin: 5px;
    color: #008b9c;
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
    transform: rotate(-90deg);
}

.companyName {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
}

.red {
    color: #ef8400;
    font-weight: 800;
}

.green {
    color: #00c911;
    font-weight: 800;
}

.selectLimit {
    min-width: 100px;
}

.limitContainer {
    display: flex;
    align-items: center;
    gap: 10px;
}

.limitTitle {
    color: #303e65;
    font-size: 14px;
    font-weight: 500;
}
