@import "../../../../../mixin";

.root {
    width: 100%;
}

.section {
    display: flex;
    padding: 43px 0;
    border-bottom: 1px solid #e5eaf5;

    @include media-custom(1300) {
        flex-direction: column;
    }

    @include mobile {
        flex-direction: column;
        align-items: center;
    }
}

.sectionTitle {
    width: 50%;
    color: #008b9c;
    font-size: 18px;
    font-weight: 300;
    line-height: 21px;

    @include mobile {
        width: 100%;
        text-align: center;
    }
}

.sectionContent {
    width: 100%;

    @include mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.flexContainer {
    display: flex;
    align-items: flex-end;
    gap: 30px;

    @include mobile {
        width: 100%;
        flex-direction: column;
        align-items: center;
        gap: 0;
    }
}

.miniSection {
    width: 100%;
    border-bottom: 1px solid #cdd0d5;
    margin-bottom: 25px;

    @include mobile {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        gap: 0;
    }
}

.miniSectionTitle {
    font-size: 13px;
}

.field {
    width: 100%;
    max-width: 337px;
}

.branch {

    @include mobile {
        margin-bottom: 10px;
    }
}

.block {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    @include mobile {
        width: 100%;
        max-width: 337px;
    }
}

.deleteBtn {
    align-self: center;
    margin-left: 10px;
}

.deleteBtnBranch {
    align-self: center;
    margin-left: 10px;

    @include mobile {
        margin-left: 0;
    }
}

.addBtn {
    margin-bottom: 20px;
    margin-left: 150px;

    @include mobile {
        margin-left: 0;
    }
}

.addBtnBranch {
    display: flex;
    width: 100%;
    max-width: 700px;
    justify-content: center;
}

.btn {
    width: 215px;
    height: 48px;
    margin-left: 367px;
    font-size: 14px;

    @include mobile {
        width: 100%;
        max-width: 337px;
        margin: 0;
    }
}

.btnContainer {
    display: flex;
    width: 100%;
    justify-content: flex-end;

    @include mobile {
        justify-content: center;
    }
}
