@import "../../mixin";

.root {
    width: 100%;
    height: 100px;
    padding: 0 100px;
    padding-top: 30px;

    @include mobile {
        height: 60px !important;
        padding: 25px 30px 0;
    }
}

.progressBar {
    position: relative;
    width: 100%;
    height: 1px;
    background-color: #22404d;
}
