@import "../../../../mixin";

.root {
    width: 100%;
    transition: 0.4s;
}

.container {
    width: 100%;
    padding: 0 15px 0 35px;
    transition: 0.4s;

    &.active {
        width: calc(100% - 372px);

        @include mobile {
            width: 100%;
            padding: 10px;
        }
    }
}

.header {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
}

.title {
    color: #303e65;
    font-size: 24px;
    font-weight: 500;
}

.statesSelect {
    width: 200px;
    margin-left: 20px;
}

.generalInfoItems {
    width: 100%;
    padding: 12px 15px 50px 35px;
    margin-bottom: 20px;
    background-color: #f8faff;
    transition: 0.3s;

    @include mobile {
        padding: 0;
    }

    &.active {
        width: calc(100% - 376px);

        @include mobile {
            width: 100%;
        }
    }
}

.generalInfoWidgets {
    display: flex;
    justify-content: space-between;
}

.generalInfoTitle {
    margin: 0 10px 0 5px;
    font-size: 18px;
    font-weight: 600;
}

.generalInfoHeader {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
}

.quickListsHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.quickListsTitle {
    font-size: 18px;
    font-weight: 600;
}

.btnCustomise {
    margin-left: auto;

    @include mobile {
        display: none;
    }
}

.quickListMb {
    margin-bottom: 80px;
}
