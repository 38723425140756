@import "../../../../mixin";

$background-color: #fff;
$border-color: #e5eaf5;

.wrapper {
    display: flex;
    width: calc(100% - 82px);
    flex-direction: column;
    align-items: center;
}

.root {
    width: 100%;
}

.section {
    display: flex;
    padding: 43px 0;
    border-bottom: 1px solid #e5eaf5;
    margin: 0 47px 0 36px;

    @include media-custom(1360) {
        flex-direction: column;
    }

    @include mobile {
        align-items: center;
        margin: 0 10px;
    }
}

.btnWithoutIcon {
    display: flex;
    width: 215px;
    height: 48px;
    align-items: center;
    justify-content: center;
    font-size: 14px;

    @include mobile {
        width: 100%;
        max-width: 337px;
    }
}

.sectionTitle {
    width: 50%;
    min-width: 250px;
    color: #008b9c;
    font-size: 18px;
    font-weight: 300;
    line-height: 21px;

    @include mobile {
        text-align: center;
    }
}

.sectionContent {
    display: flex;
    width: 100%;
    flex-direction: column;

    @include mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.flexContainer {
    display: flex;
    align-items: flex-start;
    gap: 30px;

    @include mobile {
        flex-direction: column;
        gap: 0;
    }
}

.field {
    min-width: 337px;

    @include mobile {
        width: 100%;
        min-width: auto;
    }
}

.mainNote {
    display: flex;
    width: 600px;
    justify-content: center;
    color: #008b9c;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
}

.sectionNote {
    max-width: 332px;
    padding: 15px;
    margin-bottom: 15px;
    color: #008b9c;
    font-size: 12px;
    line-height: 14px;

    @include mobile {
        padding: 0 10px 10px;
    }
}

.textArea {
    min-width: 337px;
    max-width: 710px;

    @include mobile {
        width: 100%;
        min-width: auto;
        max-width: 337px;
    }
}

.btnContainer {
    display: flex;
    width: 100%;
    justify-content: flex-end;

    @include mobile {
        display: block;
    }
}

.btn {
    width: 100%;
    max-width: 215px;
}

.btnAdd {
    display: flex;
    align-items: center;
    margin-top: 10px;
    gap: 8px;
}

.btnTitle {
    width: 100%;
    max-width: 317px;
    color: #303e65;
    font-size: 14px;
    font-weight: 500;
}

.flexWrapp {
    display: flex;
    min-width: 337px;
    flex-direction: column;
    margin-bottom: 20px;
    gap: 10px;

    @include mobile {
        width: 100%;
        align-items: center;
        margin-bottom: 0;
        gap: 0;
    }
}

.socialMediaContainer {
    margin-top: 60px;
}

.socialField {
    display: flex;
    justify-content: flex-start;
    gap: 30px;

    @include mobile {
        width: 100%;
        flex-direction: column;
        align-items: center;
        margin-bottom: 0;
        gap: 0;
    }
}

.deleteBtn {
    align-self: center;
}

.hideBtn {
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    gap: 10px;
}

.publicPrivateBtn {
    display: flex;
    width: 32px;
    height: 32px;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: 1px solid $border-color;
    background-color: $background-color;
    border-radius: 10px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.05);
    cursor: pointer;

    &:hover {
        background: darken($background-color, 5%);

        .icon {
            transform: rotate(360deg);
            transition: all 0.5s ease;
        }
    }

    &:disabled {
        background: darken($background-color, 10%);

        .icon {
            transform: rotate(0deg);
        }
    }
}

.publicIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 0;
    transition: all 0.5s ease;
}

.websiteError {
    bottom: -45px;
}

.socialFieldTitle {
    width: 100%;
    margin: 0 0 6px 16px;
    color: #303e65;
    font-size: 13px;
    font-weight: 600;
}

.teachersContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 30px;
    gap: 20px;

    @include mobile {
        gap: 0;
    }
}
