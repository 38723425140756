.root {
    display: flex;
    align-items: center;
}

.wrapper {
    display: flex;
    flex-direction: column;
}

.wrapperInput {
    display: flex;
    margin-top: 10px;
}

.preview {
    margin-right: 20px;

    img {
        object-fit: cover;
    }
}

.btnRemove {
    svg {
        fill: none !important;
    }
}

.input {
    display: none;
}

.labelUploadFile {
    display: flex;
    box-sizing: border-box;
    align-items: center;
    padding: 6px 25px 6px 10px;
    border: 1px solid #e5eaf5;
    margin-right: 15px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.05);
    font-size: 13px;
    font-weight: 600;

    &:hover {
        cursor: pointer;
    }
}

.labelUploadFileDisabled {
    background-color: darken(#fff, 3%);
    box-shadow: none;

    &:hover {
        cursor: default;
    }
}

.error {
    margin-bottom: 6px;
    margin-left: 16px;
    color: #eb5757;
    font-size: 13px;
    font-weight: 600;
}

.labelUploadFileIcon {
    margin-right: 7px;
}
