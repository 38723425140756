.root {
    display: inline-flex;
    align-items: center;
    padding: 15px 22px 17px;
    border: 1px solid #e5eaf5;
    background-color: #22404d;
    border-radius: 15px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.03);
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;

    &:hover {
        background-color: darken(#22404d, 2%);
        cursor: pointer;

        .icon {
            transform: rotate(360deg);
            transition: transform 0.5s ease;
        }
    }

    &:disabled {
        background-color: darken(#22404d, 3%);
        cursor: unset;
        opacity: 0.8;

        .icon {
            transform: rotate(0deg);
        }
    }
}

.rootLoading {
    .icon {
        transform: rotate(0deg);

        :global {
            animation: rotate360 0.5s linear infinite;
        }
    }
}

.icon {
    margin-left: auto;
    transition: transform 0.5s ease;
}

.themeGrey {
    padding: 9px;
    background-color: #008b9c;
    font-size: 14px;
    font-weight: 500;

    .icon {
        margin-right: 12px;
        margin-left: 0;
    }

    &:hover {
        background-color: darken(#008b9c, 2%);
    }

    &:disabled {
        background-color: darken(#008b9c, 3%);
        cursor: unset;
        opacity: 0.8;

        .icon {
            transform: rotate(0deg);
        }
    }
}

.themeWhite {
    display: flex;
    min-width: 32px;
    min-height: 32px;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border: 1px solid #e5eaf5;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.05);

    .icon {
        margin: 0;
    }

    &:hover {
        background-color: darken(#fff, 2%);
    }

    &:disabled {
        background-color: darken(#fff, 3%);
    }
}

.themeOrange {
    padding: 9px 14px;
    background-color: #46b17b;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;

    &:hover {
        background-color: darken(#46b17b, 2%);
    }

    &:disabled {
        background-color: darken(#46b17b, 3%);
        cursor: unset;
        opacity: 0.8;

        .icon {
            transform: rotate(0deg);
        }
    }
}

.themeDownload {
    padding: 0;
    border: 0;
    background: none;
    border-radius: 10px;
    box-shadow: none;
    color: #303e65;

    .icon {
        margin: 0;
    }

    &:hover {
        background-color: darken(#fff, 2%);
    }
}

.themeMinimal {
    min-height: unset;
    padding: 4px 8px;
    border: 0;
    background-color: transparent;
    box-shadow: none;
    color: #303e65;
    font-size: 12px;
    font-weight: 700;

    .icon {
        margin: 0 0 0 5px;
    }

    &:hover {
        background-color: transparent;
        box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
    }

    &:disabled {
        background-color: transparent;

        &:hover {
            background-color: transparent;
            box-shadow: none;
        }
    }
}

.whiteBackground {
    padding: 15px 22px 17px;
    border: 1px solid #22404d;
    background-color: #fff;
    color: #22404d;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;

    &:hover {
        background-color: #22404d;
        color: #fff;
    }

    &:disabled {
        background-color: darken(#fff, 3%);
        cursor: unset;
        opacity: 0.8;

        .icon {
            transform: rotate(0deg);
        }
    }
}

.themeRed {
    padding: 10px;
    margin-top: 10px;
    background-color: #fd0202;
    font-size: 16px;
    font-weight: 500;

    .icon {
        margin-right: 12px;
        margin-left: 0;
    }

    &:hover {
        background-color: darken(#fd0202, 3%);
    }

    &:disabled {
        background-color: darken(#fd0202, 3%);
        cursor: unset;
        opacity: 0.8;

        .icon {
            transform: rotate(0deg);
        }
    }
}
