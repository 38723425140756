@import "../../mixin";

.scrollWrapper {
    max-width: 100%;
    overflow-x: auto;
}

.root {
    width: 100%;
    min-width: 900px;
    padding-right: 15px;
    padding-left: 35px;
    margin-top: 40px;
    margin-bottom: 27px;

    @include mobile {
        padding-right: 10px;
        padding-left: 10px;
    }
}

.titles {
    display: flex;
    justify-content: space-around;
    margin-right: 40px;
    margin-bottom: 10px;
    font-size: 12px;

    @include mobile {
        font-size: 10px;
    }
}

.deadline {
    margin-bottom: 0;
}

.title {
    position: relative;
    padding-bottom: 50px;
    margin: 0;
    color: #008b9c;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.4em;
    text-align: center;
    text-transform: uppercase;

    &:nth-child(1) {
        width: 24%;
    }

    &:nth-child(2) {
        width: 26%;
    }

    &:nth-child(3) {
        width: 24%;
    }

    &:nth-child(4) {
        width: 26%;
    }

    &:nth-child(2n) {
        &::before {
            position: absolute;
            top: 0;
            bottom: -24px;
            left: 0;
            width: 100%;
            background: linear-gradient(180deg, rgba(229, 234, 245, 0) 0%, #e5eaf5 100%);
            content: "";
        }
    }
}

.reportTitle {
    position: relative;
    margin: 0;
    margin-bottom: 3px;
    color: #008b9c;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.4em;
    text-align: center;
    text-transform: uppercase;

    &:nth-child(1) {
        width: 24%;
    }

    &:nth-child(2) {
        width: 26%;
    }

    &:nth-child(3) {
        width: 24%;
    }

    &:nth-child(4) {
        width: 26%;
        margin-right: 20px;
    }

    &:nth-child(2n) {
        &::before {
            position: absolute;
            top: 0;
            bottom: -24px;
            left: 0;
            width: 100%;
            background: linear-gradient(180deg, rgba(229, 234, 245, 0) 0%, #e5eaf5 100%);
            content: "";
        }
    }
}

.deadlineText {
    position: relative;
    padding-right: 40px;
    margin: 0;
    margin-bottom: 2px;
    color: #ef8852;
    font-size: 10px;
    font-weight: 500;
    text-align: left;
}
