@import "../../../mixin";

.root {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.search {
    width: 100%;
    max-width: 440px;
    margin: 0 20px;

    @include mobile {
        margin: 10px;
    }
}

.subHeader {
    display: flex;
    width: 100%;
    min-height: 62px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 10px 48px 10px 38px;
    background: #f8faff;

    @include mobile {
        padding: 10px;
    }

    @include media-custom(350) {
        justify-content: center;
    }
}

.filterBtn {
    display: flex;
    justify-content: center;

    @include mobile {
        width: 100% !important;
    }
}

.miniHeaderContent {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    padding-right: 48px;
    margin: 30px;

    @include mobile {
        flex-direction: column;
        margin-top: 10px;
    }
}

.widgetsContainer {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 20px;

    @include mobile {
        flex-direction: column;
        margin-top: 10px;
    }
}

.btnSignIn {
    display: flex;
    min-width: 300px;
    align-self: flex-end;
    justify-content: center;
    padding: 8px;
    border: 1px solid #e5eaf5;
    background-color: #008b9c;
    border-radius: 15px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.03);
    color: #fff !important;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
    text-align: center;

    &:hover {
        background-color: darken(#008b9c, 2%);
        cursor: pointer;

        .icon {
            margin-left: 0;
            transform: rotate(360deg);
            transition: transform 0.5s ease;
        }
    }

    &:disabled {
        background-color: darken(#008b9c, 3%);
        cursor: unset;
        opacity: 0.8;

        .icon {
            transform: rotate(0deg);
        }
    }
}

.companiesContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 20px;
    gap: 20px;

    @include mobile {
        justify-content: flex-start;
        margin-left: 40px;
    }
}

.selectLimit {
    min-width: 100px;
}

.inputLimit {
    border-radius: 30%;
}

.limitContainer {
    display: flex;
    align-items: center;
    gap: 10px;
}

.limitTitle {
    color: #303e65;
    font-size: 14px;
    font-weight: 500;
}
