@import "../../../../mixin";

.root {
    width: calc(100% - 82px);
}

.header {

    @include media-custom(650) {
        display: flex;
        height: 120px;
        flex-direction: column;
        padding: 15px 0;
    }
}

.addShareBtn {
    width: 200px;
    height: 48px;
}

.tableTitleShare {
    padding-left: 20px;
}

.btnTooltipWrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding-right: 50%;
}

.shareContainer {
    display: flex;
    min-width: 100px;
    align-items: center;
    padding-left: 20px;
}

.shareholder {
    min-width: 150px;
}

.share {
    margin-left: 5px;
    color: #303e65;
    font-size: 0.85em;
    font-weight: 600;
}

.dateContainer {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}
