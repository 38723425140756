@import "../../../mixin";

.root {
    position: absolute;
    top: -11px;
    left: 0;
    width: 0;
    cursor: pointer;
}

.backgroundCircule {
    display: flex;
    width: 23px;
    height: 23px;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 50%;
}

.step {
    display: flex;
    width: 16px;
    height: 16px;
    align-items: center;
    justify-content: center;
    border: 1px solid #285f74;
    background-color: #fff;
    border-radius: 50%;
    color: #285f74;
    font-size: 0.75em;
}

.accomplished {
    border: 0;
    background-color: #285f74;
}

.stepText {
    width: 150px;
    color: #008b9c;
    font-size: 0.85em;
    font-weight: 600;
    text-align: center;
    transform: translateX(-40%);

    @include mobile {
        display: none;
    }
}
