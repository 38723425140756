@import "../../../mixin";

.quickLists {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;

    @include media-custom(1250) {
        flex-wrap: wrap;
        justify-content: space-between;
    }
}

div.quickList {
    width: 32%;

    @include media-custom(1250) {
        width: 48%;
        margin-right: 0;
        margin-bottom: 35px;

        &:last-child {
            width: 100%;
        }
    }

    @include mobile {
        width: 100%;
        margin-bottom: 70px;
    }
}
