@import "../../../../../../mixin";

.root {
    width: 100%;
}

.section {
    display: flex;
    padding: 43px 0;
    border-bottom: 1px solid #e5eaf5;

    @include media-custom(1300) {
        flex-direction: column;
    }

    @include mobile {
        align-items: center;
    }
}

.title {
    padding: 0;
    margin: 0;
    color: #008b9c;
    font-size: 14px;
    font-weight: 600;
}

.sectionTitle {
    width: 50%;
    color: #008b9c;
    font-size: 18px;
    font-weight: 300;
    line-height: 21px;

    @include mobile {
        width: auto;
    }
}

.deadline {
    color: #008b9c;
    font-size: 18px;
    font-weight: 600;
}

.description {
    color: #008b9c;
    font-size: 12px;

    p {
        margin: 0;
        margin-top: 10px;
        color: #303e65;
        font-size: 1.1em;
        font-weight: 600;
    }
}

.sectionContent {
    width: 100%;

    @include mobile {
        display: flex;
        max-width: 337px;
        flex-direction: column;
        align-items: center;
    }
}

.sectionNote {
    max-width: 332px;
    padding: 15px;
    color: #008b9c;
    font-size: 12px;
    line-height: 14px;
}

.flexContainer {
    display: flex;
    align-items: flex-end;
    gap: 30px;
    // max-width: 337px;

    @include mobile {
        width: 100%;
        flex-direction: column;
        align-items: center;
        gap: 0;
    }
}

.threeInline {
    max-width: 215px !important;

    @include mobile {
        width: 100%;
        max-width: 337px !important;
    }
}

.textArea {
    max-width: 705px;

    @include mobile {
        width: inherit;
        max-width: 337px;
    }
}

.btnContainer {
    max-width: 705px;
    justify-content: flex-end;

    @include mobile {
        gap: 10px;
    }
}

.btn {
    display: flex;
    width: 150px;
    height: 48px;
    justify-content: center;
    font-size: 14px;

    @include mobile {
        width: 100% !important;
        min-width: auto;
        max-width: 337px;
    }
}

.addBtnContainer {
    align-items: center;
    margin-top: 20px;
    gap: 5px;
    grid-gap: 5px;
}

.deleteBtn {
    align-self: center;
}

.addBtnText {
    margin: 0;
    color: #303e65;
    font-size: 12px;
    font-weight: 600;
}

input {
    &.calculated:disabled {
        background-color: #7096a8;
        color: #fff;
    }
}

.paymentCredit {
    display: flex;
    width: 100%;
    max-width: 337px;
    height: 48px;
    align-items: center;
    align-self: center;
    padding-left: 21px;
    border: 1px solid #e5eaf5;
    background-color: #e9e9e9;
    border-radius: 15px;
    font-size: 16px;
    font-weight: 500;
}

.warningIcon {
    margin-right: 10px;
}

.creditTitle {
    max-width: 700px;
    padding: 20px;
    margin: 30px 0;
    background: #e9e9e9;
    border-radius: 20px;
}

.warningContainer {
    display: flex;
    max-width: 320px;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
    font-size: 18px;
}

.warningImage {
    max-width: 32px;
    height: 35px;
    margin-right: 20px;
    border-radius: 50%;
    fill: #eb5757;
}

.warningTextColor {
    color: #ffae00;
}
