.switch {
    position: relative;
    display: inline-block;
    min-width: 32px;
    height: 16px;
    min-height: 16px;
}

.switch input {
    width: 0;
    height: 0;
    opacity: 0;
}

.slider {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #e5eaf5;
    cursor: pointer;
    transition: 0.4s;
}

.slider::before {
    position: absolute;
    bottom: 3px;
    left: 3px;
    width: 10px;
    height: 10px;
    background-color: #22404d;
    content: "";
    transition: 0.4s;
}

input:checked + .slider::before {
    transform: translateX(16px);
}

.slider.round {
    border-radius: 15px;
}

.slider.round::before {
    border-radius: 50%;
}
