$color-loader: #285f74;

.root {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.loader {
    position: absolute;
    width: 100%;
    max-width: 200px;
    height: 50px;
    color: $color-loader;
    font-family: helvetica, arial, sans-serif;
    font-weight: 700;
    letter-spacing: 0.2em;
    line-height: 50px;
    text-align: center;
    text-transform: uppercase;

    &::after {
        position: absolute;
        bottom: 15px;
        left: -10px;
        display: block;
        width: 25px;
        height: 25px;
        animation: load 0.5s alternate infinite ease;
        background: $color-loader;
        border-radius: 50%;
        content: "";
    }
}

@keyframes load {
    0% {
        bottom: 15px;
        height: 10px;
        border-radius: 60px 60px 10px 10px;
        transform: scaleX(2);
    }

    35% {
        height: 20px;
        border-radius: 50%;
        transform: scaleX(1);
    }

    100% {
        bottom: 50px;
    }
}
