.root {
    width: 100%;
    height: 24px;
}

.bar {
    position: relative;
    z-index: 0;
    display: flex;
    height: 100%;
    align-items: center;
    padding: 4px 0;
    background-color: #e5eaf5;
    border-radius: 10px;
}

.progress {
    z-index: -1;
    height: 100%;
    margin-right: 3px;
    margin-left: 3px;
    background: #22404d;
    border-radius: 10px 0 0 10px;
    transition: width 0.3s ease;
}
