@import "../../mixin";

.root {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 30px;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    border: 1px solid rgb(153, 116, 4);
    background-color: rgb(251, 140, 0);
    color: #fff;
}

.description {
    margin: 0;
}

.exitBtn {
    padding: 3px 20px;
}
