.notificationsItem {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 1px solid #e5eaf5;
}

.title {
    margin: 0 0 10px;
    font-weight: bold;
}

.description {
    font-size: 15px;
    word-break: break-word;
}

.message {
    display: flex;
    max-width: 85%;
    align-items: center;
}

.titleRejectCompany {
    composes: title;
    color: #eb5757;
}

.titleFinish {
    composes: title;
    color: #018101;
}

.warningIcon {
    max-width: 30px;
    height: 30px;
    margin-right: 25px;
    margin-left: auto;
}

.congratulationIcon {
    max-width: 30px;
    height: 30px;
    border: 1px solid #fff;
    margin-right: 25px;
    margin-left: auto;
    background-color: #fff;
}

.infoIcon {
    max-width: 35px;
    height: 35px;
    border: 1px solid #fff;
    margin-right: 25px;
    margin-left: auto;
    background-color: #fff;
}

.deadlineText {
    color: #ef8852;
    font-size: 14px;
    font-weight: 500;
}

.link {
    color: #00e;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
}

.btnSubmitReview {
    padding: 12px;
    border: 1px solid #e5eaf5;
    margin-top: 10px;
    background-color: #22404d !important;
    color: #fff !important;

    &:hover {
        background-color: darken(#22404d, 2%) !important;
    }

    &:disabled {
        background-color: #ccc !important;
        color: #303e65;
    }
}
