.root {
    display: flex;
    width: 100%;
    min-height: 82px;
    align-items: center;
    justify-content: space-between;
    padding-right: 48px;
    padding-left: 38px;
}

.flatLink {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;

    &:hover {
        opacity: 0.7;
        transition: all 0.5s ease;

        .arrowLeft {
            transform: rotate(540deg);
            transition: all 0.5s ease;
        }
    }
}

.arrowLeft {
    margin: 0;
    margin-right: 8px;
    transform: rotate(180deg);
    transition: all 0.5s ease;
}

.title {
    margin: 0;
    color: #303e65;
    font-size: 24px;
    font-weight: 300;
}
