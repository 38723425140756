@import "../../mixin";

.root {
    display: flex;
    height: 100px;
    flex-direction: column;
    justify-content: center;
    padding: 15px 20px 20px;
    background-color: #e5eaf5;
    border-radius: 20px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.05);
}

.header {
    display: flex;
    align-items: center;
}

.newCount {
    margin-left: 15px;

    @include mobile {
        margin-right: 10px;
    }
}

.linkFullList {
    margin-left: auto;
}

.title {
    margin: 0;
    color: #303e65;
    font-size: 14px;
    font-weight: 600;
}

.info {
    color: #008b9c;
    font-size: 10px;
}

.emptyBlock {
    display: flex;
    height: 100px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #f2f5fb;
    border-radius: 20px;
    box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.05), inset 0 5px 20px rgba(0, 0, 0, 0.03);
}

.emptyBlockMessage {
    margin: 5px 0 0;
    font-size: 12px;
    font-weight: 400;
}

.avatars {
    display: flex;
    align-items: center;
    margin-top: 6px;

    @include media-custom(450) {
        div:nth-child(n + 5) {
            display: none !important;
        }
    }
}

.avatar {
    border: 1px solid #e5eaf5;
    margin-left: -6px;

    &:first-child {
        margin-left: 0;
    }
}

.btnAdd {
    margin-left: auto;
}
