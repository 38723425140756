@import "../../../../mixin";

.root {
    width: 100%;
    margin-bottom: 30px;
}

.empty {
    display: flex;
    width: 100%;
    height: 70%;
    align-items: center;
    justify-content: center;
}

.emptyDescription {
    color: #008b9c;
    font-size: 25px;
    font-weight: bold;
    text-align: center;
}

.dateBlock {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 10px;
}

.messageList {
    margin: 10px 40px;
}
