@import "../../../../mixin";

.root {
    width: 100%;

    @include tablet {
        width: 100%;
    }
}

.rootAdmin {
    width: calc(100% - 282px);

    @include tablet {
        width: calc(100% - 82px);
    }
}

.newsList {
    display: grid;
    margin-top: 40px;
    margin-bottom: 20px;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr 1fr;

    @include tablet {
        grid-template-columns: 1fr 1fr;
    }

    @include mobile {
        grid-template-columns: 1fr;
    }
}

.link {
    align-self: end;
    color: #00e;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
}

.spanName {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 3px;
    word-break: break-word;
}

.nameWrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding-right: 5px;
}

.miniHeader {

    @include mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;
        gap: 10px;
    }
}

.btnToolTip {
    margin-left: auto;
}

.filterBtn {
    display: flex;
    width: 150px;
    align-items: center;
    justify-content: center;

    @include mobile {
        width: 100%;
    }
}

.miniHeaderContent {
    display: flex;
    align-items: center;

    @include mobile {
        flex-direction: column;
        margin-top: 10px;
    }
}

.createButton {
    text-transform: none;
}
