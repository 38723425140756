.root {
    display: flex;
    align-items: center;
    margin-bottom: 17px;
}

.wrapper {
    width: 100%;
}

.titleLink {
    text-decoration: none;
}

.title {
    margin-top: 0;
    margin-bottom: 2px;
    color: #22404d;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
}

.description {
    overflow: hidden;
    width: 100%;
    margin: 0;
    color: #008b9c;
    font-size: 12px;
    font-weight: 500;
    line-height: 1;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.time {
    color: #008b9c;
    font-size: 10px;
    font-weight: normal;
    line-height: 12px;
}

.txt,
.companyTxt {
    margin-right: 8px;
    margin-left: 5px;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
}

.companyTxt {
    font-weight: 600;
}

.info {
    display: flex;
    align-items: center;
}

.avatar {
    img {
        object-fit: cover;
    }
}

.icon {
    margin-right: 8px;
}

.name {
    margin-left: 10px;
    color: #303e65;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
}

.arrowBtn {
    padding: 3px;
    border: 0;
    margin-left: 30px;
    background: none;
    box-shadow: none;

    &:hover {
        background: none;
    }
}
