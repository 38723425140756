.root {
    position: relative;
    display: flex;
    width: 100%;
    max-width: 337px;
    flex-direction: column;
}

.error,
.inputName {
    margin-bottom: 6px;
    margin-left: 16px;
    color: #303e65;
    font-size: 13px;
    font-weight: 600;
}

.error {
    position: absolute;
    bottom: -25px;
    color: #eb5757;
}

.select {
    :global {
        .select__control {
            min-height: 48px;
            padding: 0 10px;
            border: 0;
            background-color: #f8faff;
            border-radius: 15px;
            box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.05), inset 0 5px 20px rgba(0, 0, 0, 0.03);

            &:hover {
                cursor: pointer;
            }
        }

        .select__placeholder {
            color: #008b9c;
            font-weight: 400;
        }

        .select__control--is-disabled {
            background-color: #e9e9e9;
        }

        .select__option {
            padding: 15px;
            background: none;
            border-radius: 15px;
            color: #008b9c;
            cursor: pointer;

            &:active {
                background-color: #f8faff;
            }
        }

        .select__option--is-focused {
            background-color: #f8faff;
        }

        .select__menu {
            padding: 0;
            border-radius: 15px;
        }

        .select__single-value {
            color: #303e65;
            font-weight: 500;
        }

        .select__indicator-separator {
            display: none;
        }
    }
}

.require {
    color: #eb5757;
    font-size: 15px;
}
