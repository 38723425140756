.root {
    display: flex;
    min-width: 24px;
    height: 24px;
    align-items: center;
    justify-content: center;
    background-color: #73be3f;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.05);
    color: #fff;
    font-size: 10px;
    font-weight: 500;
}
