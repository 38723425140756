.root {
    width: 100%;
    margin-bottom: 50px;
}

.tableWrapper {
    max-width: 100%;
    overflow-x: auto;
}

.table {
    width: 100%;
    margin-top: 20px;
    border-collapse: separate;
    border-spacing: 0 10px;
    text-align: left;
}

.title {
    display: flex;
    padding-bottom: 10px;
    border-bottom: 1px solid #e5eaf5;
    color: #008b9c;
    font-size: 12px;
    font-weight: 500;
}

.btnOrderAsc {
    margin-right: 3px;
    margin-left: 15px;
    transform: rotate(180deg);
}

.row {
    &:hover {
        background-color: #f8faff;

        tr,
        td {
            background-color: #f8faff;
        }
    }
}

.cell {
    display: flex;
    min-height: 50px;
    align-items: center;
    color: #008b9c;
}
