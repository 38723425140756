.root {
    display: flex;
    height: 100px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #f2f5fb;
    border-radius: 20px;
    box-shadow: inset 0 1px 4px rgb(0 0 0 / 5%), inset 0 5px 20px rgb(0 0 0 / 3%);
}

.rootExistsForm {
    background-color: #e5eaf5;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.05);
}

.existsForm {
    display: flex;
    align-items: center;

    &:hover {
        cursor: pointer;
    }
}

.wrapperIcon {
    display: flex;
    width: 38px;
    height: 38px;
    align-items: center;
    justify-content: center;
    border: 1px solid #e5eaf5;
    margin-right: 8px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.05);
}

.empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.wrapper {
    display: flex;
    flex-direction: column;
}

.title {
    margin: 0;
    color: #303e65;
    font-size: 14px;
    font-weight: 600;
}

.description {
    color: #008b9c;
    font-size: 12px;
}

.emptyBlockTitle {
    margin-top: 5px;
    color: #008b9c;
    font-size: 12px;
}
