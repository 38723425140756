@import "../../../../mixin";

.root {
    width: calc(100% - 282px);

    @include tablet {
        width: calc(100% - 82px);
    }
}

.subHeader {
    display: flex;
    width: 100%;
    min-height: 62px;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 10px 48px 10px 38px;
    background: #f8faff;

    @include mobile {
        padding: 10px;
    }

    @include media-custom(350) {
        justify-content: center;
    }
}

.headerContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;

    @include mobile {
        flex-direction: column;
        align-items: center;
        padding: 10px;
        gap: 10px;
    }
}

.header {
    display: flex;

    @include media-custom(1250) {
        flex-direction: column;
    }
}

.toggleContainer {
    display: flex;
    align-items: center;

    @include media-custom(442) {
        flex-direction: column;
    }
}

.toggleLeftTitle {
    margin-right: 12px;
    color: #008b9c;
    font-size: 0.75em;
    text-transform: uppercase;

    @include media-custom(442) {
        margin-right: 0;
    }
}

.toggleRightTitle {
    margin-left: 12px;
    color: #008b9c;
    font-size: 0.75em;
    text-transform: uppercase;

    @include media-custom(442) {
        margin-left: 0;
    }
}

.searchContainer {
    display: flex;
    align-items: center;
    margin-left: 190px;

    @include media-custom(1450) {
        margin-left: 90px;
    }

    @include media-custom(1333) {
        margin-left: 0;
    }

    @include mobile {
        flex-direction: column;
        align-items: center;
    }
}

.filterBtn {
    display: flex;
    width: 150px;
    align-items: center;
    justify-content: center;
    margin-left: 10px;

    @include mobile {
        width: 100%;
        margin-top: 10px;
    }
}

.tableTitleCompanyName {
    padding-left: 20px;
}

.companyNameContainer {
    display: flex;
    align-items: center;
    padding-left: 20px;
}

.companyName {
    margin-left: 20px;
    color: #303e65;
    font-size: 0.88em;
    font-weight: 600;
}

.defaultAvatar {
    display: flex;
    width: 32px;
    height: 32px;
    align-items: center;
    justify-content: center;
    border: 2px solid #3bb055;
    background-color: #303e65;
    border-radius: 50%;
    color: #008b9c;
    font-weight: 400;
}

.emploees {
    margin-left: 20px;
}

.emploees,
.companyType,
.industryType,
.status {
    font-size: 0.85em;
}

.statusActive {
    color: #285f74;
}

.statusVerification {
    color: #ef8400;
}

.optionBtnCell {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-right: 20px;
}

.optionBtn {
    margin-left: 20px;
}

.name {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding-right: 10%;
}
