@import "../../../../mixin";

.root {
    width: 100%;
}

.userWrapperContent {
    background-image: linear-gradient(to bottom, #fff 40px, #f8faff 0);
}

.user {
    display: flex;
}

.userWrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 45px;
}

.userNameWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @include media-custom(420) {
        display: none;
    }
}

.helloMessage {
    margin: 5px 0 0;
    font-size: 24px;
    font-weight: 300;
}

.userAvatar {
    margin-right: 20px;

    @include media-custom(420) {
        margin-right: 5px;

        img {
            object-fit: cover;
        }
    }

    img {
        object-fit: cover;
    }
}

.userName {
    margin: 0 0 5px;
    font-size: 24px;
    font-weight: 400;
}

.editProfileIcon {
    margin-left: 13px;
}

.infoPanel {
    display: flex;
    align-items: center;
    padding-bottom: 25px;
    margin-left: 96px;

    @include media-custom(1415) {
        margin-left: 0;
    }

    @include media-custom(1320) {
        flex-wrap: wrap;
        justify-content: space-between;
    }
}

.infoPanelItem {
    width: 100%;
    margin-right: 35px;

    @include media-custom(1320) {
        width: 48%;
        margin-top: 15px;
        margin-right: 0;
    }

    @include mobile {
        width: 100%;
    }

    &:last-child {
        margin-right: 0;
    }
}

.locationWrapper {
    composes: infoPanelItem;
    max-width: 246px;

    @include media-custom(1320) {
        width: 100%;
        max-width: unset;
    }
}
