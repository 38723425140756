@import "../../../../mixin";

.wrapper {
    width: calc(100% - 82px);
}

.root {
    width: 100%;
}

.section {
    display: flex;
    align-items: flex-start;
    padding: 43px 0;
    border-bottom: 1px solid #e5eaf5;
    margin: 0 47px 0 36px;

    @include media-custom(1300) {
        flex-direction: column;
    }

    @include media-custom(500) {
        margin: 0 15px;
    }
}

.sectionModal {
    display: block;
}

.sectionTitle {
    display: flex;
    width: 50%;
    align-items: center;
    color: #008b9c;
    font-size: 18px;
    font-weight: 300;
    line-height: 21px;

    @include mobile {
        width: auto;
        text-align: center;
    }
}

.sectionTitleModal {
    margin-bottom: 40px;
}

.parentalTitle {
    display: flex;
    width: 50%;
    align-items: center;

    @include mobile {
        width: auto;
        text-align: center;
    }
}

.parentalTitleText {
    color: #008b9c;
    font-size: 18px;
    font-weight: 300;
    line-height: 21px;
}

.sectionContent {
    width: 100%;

    @include mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.sectionNote {
    max-width: 332px;
    padding: 15px;
    color: #008b9c;
    font-size: 12px;
    line-height: 14px;
}

.flexContainer {
    display: flex;
    align-items: center;
    gap: 30px;

    @include mobile {
        width: 100%;
        max-width: 337px;
        flex-direction: column;
        align-items: flex-start;
        gap: 0;
    }
}

.field {
    width: 100%;
    max-width: 337px;
}

.avatarUploader {
    margin-bottom: 20px;

    @include media-custom(650) {
        flex-direction: column;
    }
}

.alert {
    margin-bottom: 43px;

    @include mobile {
        max-width: 337px;
    }
}

.uploader {

    @include mobile {
        width: 100%;
    }
}

.location {
    max-width: 700px;
    border-bottom: 1px solid #e5eaf5;
    margin-bottom: 27px;

    @include mobile {
        max-width: 337px;
        border-bottom: 0;
        margin-bottom: 27px;
    }
}

.securityBtnIcon {
    margin-right: 10px;
    margin-left: 0;
}

.btn {
    width: 215px;
    height: 48px;
    margin-left: 367px;
    font-size: 14px;

    @include mobile {
        width: 100%;
        max-width: 337px;
        margin: 0;
    }
}

.saveBtn {
    display: flex;
    align-self: flex-end;
    justify-content: flex-end;
    margin: 37px 48px 84px 0;
}

.btnContainer {
    display: flex;
    width: 100%;
    justify-content: flex-end;

    @include mobile {
        justify-content: center;
        margin-top: 10px;
    }
}

.btnWithoutIcon {
    display: flex;
    width: 215px;
    height: 48px;
    align-items: center;
    justify-content: center;
    font-size: 14px;

    @include mobile {
        width: 100%;
        max-width: 337px;
    }
}

.parentalConfirmation {
    display: flex;
    align-items: center;

    @include mobile {
        width: 100%;
        max-width: 337px;
        flex-direction: column;
        gap: 10px;
    }
}

.btnDownload {
    width: 32px;
    height: 32px;
    margin-left: 20px;

    @include mobile {
        margin-left: 0;
    }
}

.disabledLabel {
    background: #305a4f40;
}

.parentalConfirmationWrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.warningParentalConfirmation {
    max-width: 40px;
    height: 40px;
    border: 1px solid #fff;
    margin-left: 30px;
}

.btnAdd {
    display: flex;
    align-items: center;
    gap: 8px;
}

.btnTitle {
    width: 100%;
    max-width: 317px;
    color: #303e65;
    font-size: 12px;
    font-weight: 500;
}

.info {
    max-width: 24px;
    height: 24px;
}

.mainFunction {
    display: flex;
    align-items: center;
    gap: 30px;

    @include mobile {
        width: 100%;
        max-width: 337px;
        flex-direction: column;
        align-items: flex-start;
        gap: 0;
    }
}
