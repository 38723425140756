.root {
    display: flex;
    width: 245px;
    height: 205px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #e5eaf5;
    border-radius: 10%;
}

.notificationCircle {
    display: flex;
    width: 24px;
    height: 24px;
    align-items: center;
    justify-content: center;
    background-color: #73be3f;
    border-radius: 50%;
    color: #fff;
    font-size: 0.5em;
}

.firstRow {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #bcc9e4;
}

.firstCol {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #bcc9e4;
}

.secondCol {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.secondRow {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.mainNumber {
    margin: 0;
    font-size: 1.75em;
}

.text {
    margin: 0;
    font-size: 0.9em;
    font-weight: bold;
}

.plus {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    margin: 0;
    cursor: pointer;
}
