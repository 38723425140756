@import "../../../../mixin";

.root {
    width: calc(100% - 282px);

    @include tablet {
        width: calc(100% - 82px);
    }
}

.subHeader {
    display: flex;
    width: 100%;
    min-height: 62px;
    align-items: center;
    justify-content: space-between;
    padding: 10px 48px 10px 38px;
    background: #f8faff;

    @include mobile {
        flex-direction: column;
        align-items: center;
    }
}

.userCounter {
    color: #303e65;
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
}

.avatar {
    margin-right: 17px;

    img {
        object-fit: cover;
    }
}

.rootLoading {
    transform: rotate(0deg);

    :global {
        animation: rotate360 0.5s linear infinite;
    }
}

.downloadContent {
    display: flex;
    align-items: center;
    color: #303e65;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;

    @include media-custom(600) {
        display: block;
    }
}

.miniHeader {

    @include mobile {
        display: flex;
        flex-direction: column;
        padding: 10px;
    }

    &Content {
        display: flex;
        align-items: center;

        @include mobile {
            display: flex;
            flex-direction: column;
        }
    }
}

.search {
    width: 100%;
    max-width: 440px;
    margin: 0 10px;

    @include mobile {
        width: 100%;
        max-width: 250px;
        margin: 10px 0;
    }
}

.filterBtn {
    display: flex;
    width: 150px;
    justify-content: center;

    @include mobile {
        width: 100%;
        max-width: 250px;
    }
}

.actionGreen:not(:disabled) {
    color: #22404d;
}

.parental {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.nameWrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
}
