.root {
    position: relative;
    display: flex;
    width: 100%;
    max-width: 337px;
    flex-direction: column;
}

.error,
.inputName {
    margin-bottom: 6px;
    margin-left: 16px;
    color: #303e65;
    font-size: 13px;
    font-weight: 600;
}

.error {
    position: absolute;
    bottom: -25px;
    color: #eb5757;
}

.passwordBtn {
    position: absolute;
    top: 33px;
    right: 10px;
    width: 24px;
    height: 24px;
    padding: 0;
    border: 0;
    background: none;
    cursor: pointer;
    transition: transform 200ms ease;

    &:hover {
        transform: scale(1.1);
        transition: transform 200ms ease;
    }
}

.passwordRepeatBtnError {
    position: absolute;
    top: 50px;
    right: 10px;
    width: 24px;
    height: 24px;
    padding: 0;
    border: 0;
    background: none;
    cursor: pointer;
    transition: transform 200ms ease;

    &:hover {
        transform: scale(1.1);
        transition: transform 200ms ease;
    }
}

.require {
    color: #eb5757;
    font-size: 15px;
}

.passwordBtnError {
    position: absolute;
    top: 50px;
    right: 10px;
    width: 24px;
    height: 24px;
    padding: 0;
    border: 0;
    background: none;
    cursor: pointer;
    transition: transform 200ms ease;

    &:hover {
        transform: scale(1.1);
        transition: transform 200ms ease;
    }
}

.input {
    width: 100%;
    padding: 15px 21px;
    border: 0;
    background-color: #f8faff;
    border-radius: 15px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.05), inset 0 5px 20px rgba(0, 0, 0, 0.03);
    color: #303e65;
    font-weight: 500;
    outline: none;

    &::placeholder {
        color: #008b9c;
        font-weight: 400;
    }

    &:focus {
        box-shadow: 2px 1px 9px 2px rgba(74, 82, 87, 0.18);
    }

    &:disabled {
        background-color: #e9e9e9;
    }
}
