@import "../../mixin";

$background-color: #fff;
$border-color: #e5eaf5;

.root {
    display: flex;
    flex-direction: column;
}

.error {
    margin-bottom: 6px;
    margin-left: 16px;
    color: #eb5757;
    font-size: 13px;
    font-weight: 600;
}

.wrapper {
    display: flex;
    width: 333px;
    height: 80px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #e5eaf5;
    border-radius: 20px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.05);
    cursor: pointer;

    @include mobile {
        width: 100%;
        max-width: 100%;
        min-height: 100px;
    }

    @include tablet {
        min-height: 100px;
    }
}

.upload {
    display: none;
}

.content {
    display: flex;
    max-width: 100%;
    align-items: center;
    padding-right: 10px;
    margin-left: 20px;
    word-break: break-word;
}

.icon {
    display: flex;
    min-width: 38px;
    align-items: center;
    justify-content: center;
    border: 1px solid #e5eaf5;
    margin-right: 17px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.05);
}

.title {
    margin: 0;
    color: #303e65;
    font-size: 14px;
    font-weight: 600;
}

.fileName {
    color: #008b9c;
    font-size: 12px;
    word-break: break-all;
}

.btnAdd {
    margin-right: 17px;
}

.plusIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.5s ease;
}

.reviewedParentalConfirmation {
    margin-top: 5px;
    color: #92e757;
    font-size: 18px;
    font-weight: bold;
}

.wrapperPlusIcon {
    width: 32px;
    height: 32px;
    align-items: center;
    justify-content: center;
    margin-right: 17px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.05);
    cursor: pointer;

    .plusIcon {
        margin-right: auto;
        margin-left: auto;
    }

    &:hover {
        .plusIcon {
            transform: rotate(360deg);
            transition: transform 0.5s ease;
        }
    }
}

.btnRemove {
    margin-left: 10px;
}

.loaderContainer {
    height: 100%;
}
