.root {
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    font-weight: 700;
    line-height: 1;
    text-decoration: none;
    transition: all 0.5s ease;
    white-space: nowrap;

    &:hover {
        opacity: 0.7;
        transition: all 0.5s ease;

        .icon {
            transform: rotate(360deg);
            transition: all 0.5s ease;
        }
    }
}

.rootDisabled {
    opacity: 0.5;

    &:hover {
        opacity: 0.5;

        .icon {
            transform: rotate(0deg);
        }
    }
}

.icon {
    margin-left: 7px;
    transition: all 0.5s ease;
}
