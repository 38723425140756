@import "../../../../mixin";

.root {
    width: calc(100% - 82px);
}

.miniHeader {

    @include mobile {
        flex-direction: column;
        padding: 10px;
        gap: 10px;
    }
}

.tableTitleName {
    padding-left: 20px;
}

.nameContainer {
    display: flex;
    min-width: 250px;
    align-items: center;
    padding-left: 20px;
}

.avatarWrapper {
    display: flex;
    align-items: center;
}

.avatar {
    width: 32px;
    height: 32px;
    border: 1px solid #e5eaf5;
    border-radius: 50%;
}

.userAvatar {
    img {
        object-fit: cover;
    }
}

.position {
    min-width: 80px;
}

.phone {
    min-width: 150px;
}

.name,
.position,
.phone,
.parental,
.status {
    font-size: 13px;
}

.name {
    margin-left: 20px;
    color: #303e65;
    font-size: 13px;
    font-weight: 600;
}

.statusContainer {
    display: flex;
    min-width: 150px;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
}

.statusActive {
    color: #285f74;
}

.statusVerification {
    color: #ef8400;
}

.addEmployeeBtn {
    width: 150px;
    height: 48px;

    @include mobile {
        width: 225px;
    }
}

.warning {
    max-width: 25px;
    height: 25px;
    margin-right: 5px;
    margin-left: auto;
    border-radius: 50%;
}

.parentalWrapper {
    display: flex;
    align-items: center;
}

.checked {
    max-width: 20px;
    height: 20px;
    margin-right: 5px;
    margin-left: auto;
    border-radius: 50%;
}

.statusWrapper {
    display: flex;
    align-items: center;
}

.warningStatus {
    max-width: 20px;
    height: 20px;
    border-radius: 50%;
}
