.root {
    position: fixed;
    z-index: 3;
    top: 0;
    left: 100%;
    width: 340px;
    height: 100%;
    padding: 27px 15px;
    background: #008b9c;
    overflow-y: auto;
    transition: 0.5s;

    &::-webkit-scrollbar {
        width: 0;
    }
}

.open {
    transform: translateX(-100%);
    transition: 0.5s;
    visibility: visible;
}

.closeBtn {
    display: flex;
    width: 32px;
    height: 32px;
    justify-content: center;
    border: 1px solid #fff;
    background: none;
    border-radius: 10px;

    &:hover {
        background: darken(#008b9c, 10%);
    }

    &Icon {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 12px;

            path {
                stroke: #fff;
            }
        }
    }
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 14px;
}

.title {
    width: 100px;
    margin-left: 15px;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
}

.btnClear {
    width: 94px;
    height: 34px;
    border: 1px solid #fff;
    background: none;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;

    &:hover {
        background: darken(#008b9c, 10%);
    }
}

.sectionBlock {
    margin-bottom: 20px;
}

.filterSectionHeader {
    display: flex;
    align-items: center;
}

.main {
    display: flex;
    flex-direction: column;
}

.optionBlocks {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    gap: 14px;
}

.optionBlock {
    display: flex;
    max-width: 260px;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
    background: #8391ba;
    border-radius: 10px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
    color: #fff;
}

.option {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.optionName {
    max-width: 250px;
    max-height: 50px;
    color: #ffeaea;
    font-size: 15px;
    overflow-y: hidden;
    word-break: break-word;
}

.btnCancelOption {
    padding: 0;
    border: 0;
    margin: 0 0 0 9px;
    background-color: transparent;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
}

.search {
    margin: 20px 0;

    input {
        height: 32px;
        box-shadow: 0 6px 12px rgb(0, 0, 0, 0.1);

        &::placeholder {
            color: #e5eaf5;
            opacity: 1;
        }
    }
}

.popupOptions {
    margin-left: 30px;
}

.mainCheck {
    background-color: #596a9a;
    color: #fff;

    &::before {
        top: 0;
        left: 0;
        color: #fff;
        transform: translate(40%, -10%);
    }
}

.btnLocalClear {
    padding: 0;
    border: 0;
    margin-left: auto;
    background-color: transparent;
    color: #fff;
    font-size: 12px;

    &:hover {
        cursor: pointer;
    }
}

.check {
    background: #596a9a;

    &::before {
        background: transparent url("../../assets/icons/checkTets.svg") no-repeat;
    }

    &Name {
        color: #ffeaea;
        font-size: 15px;
        word-break: break-word;
    }
}

.btn {
    display: flex;
    width: 94px;
    height: 34px;
    align-self: center;
    justify-content: center;
    border: 1px solid #fff;
    margin-top: 20px;
    background: none;
    border-radius: 10px;
    color: #fff;

    &:hover {
        background: darken(#008b9c, 10%);
    }
}
