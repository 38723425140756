@import "../../../../../mixin";

.root {
    width: 100%;
}

.section {
    display: flex;
    padding: 43px 0;
    border-bottom: 1px solid #e5eaf5;

    @include media-custom(1400) {
        flex-direction: column;
    }

    @include mobile {
        align-items: center;
    }
}

.sectionTitle {
    width: 50%;
    min-width: 350px;
    color: #008b9c;
    font-size: 18px;
    font-weight: 300;
    line-height: 21px;

    @include mobile {
        width: auto;
        min-width: auto;
    }
}

.title {
    padding: 0;
    color: #008b9c;
    font-size: 14px;
    font-weight: 600;
}

.sectionContent {
    width: 100%;

    @include mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.deadline {
    color: #008b9c;
    font-size: 18px;
    font-weight: 600;
}

.flexContainer {
    display: flex;
    align-items: center;
    gap: 30px;

    @include mobile {
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 0;
    }
}

.mainNote {
    display: flex;
    justify-content: center;
    padding: 10px;
    color: #008b9c;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
}

.noteTitle {
    padding: 0;
    margin: 0;
    font-size: 14px;

    @include mobile {
        max-width: 337px;
        margin-bottom: 18px;
    }

    p {
        padding: 0;
        margin: 0;
        font-weight: 700;
    }

    span {
        color: #008b9c;
        font-size: 12px;
    }
}

.field {
    width: 100%;
    min-width: 337px;
    margin-bottom: 36px;

    @include mobile {
        min-width: auto;
        margin-bottom: 18px;
    }
}

.btnContainer {
    display: flex;
    max-width: 705px;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    gap: 10px;

    @include mobile {
        width: 100%;
        align-items: center;
        gap: 0;
    }
}

.btn {
    display: flex;
    width: 150px;
    justify-content: center;

    @include mobile {
        width: 100% !important;
        min-width: auto;
        max-width: 337px;
        margin-bottom: 10px;
    }
}
