.root {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.infoPage {
    color: #303e65;
    font-size: 17px;
    font-weight: 600;
}

.container {
    display: flex;
    align-items: center;
    color: #008b9c;
    font-size: 14px;
    font-weight: 500;
    list-style-type: none;
    user-select: none;
}

.previous {
    margin-right: 5px;
}

.page {
    margin-right: 5px;
}

.pageLink,
.link {
    display: flex;
    min-width: 24px;
    height: 28px;
    align-items: center;
    justify-content: center;
    padding-right: 2px;
    padding-left: 2px;
    border: 1px solid #e5eaf5;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.05);
    cursor: pointer;
    transition: transform 300ms ease;

    &:hover {
        transform: scale(1.15);
    }
}

.previousLink {
    composes: link;
    transform: rotate(180deg);

    &:hover {
        transform: scale(1.15) rotate(180deg);
    }
}

.nextLink {
    composes: link;
}

.breakLabel {
    margin-right: 5px;
    color: #008b9c;
    cursor: pointer;
    font-weight: 600;
}

.active {
    color: #303e65;
    font-weight: 600;
}
