.form {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.step {
    margin-bottom: 30px;
}

.stepTitleWrapper {
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.stepTitle {
    margin-right: 10px;
    margin-left: 10px;
}

.stepTitleHr {
    width: 100%;
    border: 0;
    border-top: 1px solid #e5eaf5;
    margin: 0;
}

.btnsEmail {
    display: flex;
    justify-content: flex-end;
}

.selectField {
    max-width: 100%;
}

.selectFieldOption {
    display: flex;
    align-items: center;
}

.btns {
    display: flex;
    align-items: center;
}

.btnLogout {
    padding: 12px 22px 14px;
    color: #303e65;
}

.btnLogoutIcon {
    margin-left: 10px !important;
}

.btnSubmit {
    margin-left: auto;
}

.flexContainer {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.btnAdd {
    width: 48px;
    height: 48px;
    border: 1px solid #307062;
    margin-left: 10px;
    border-radius: 15px;
}
