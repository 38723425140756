@import "../../mixin";

.overlay {
    position: fixed;
    z-index: 10;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: rgba(#e5eaf5, 0.75);
    overflow-x: hidden;
    overflow-y: scroll;
}

.container {
    padding-right: 64px;
    padding-left: 64px;

    @include mobile {
        padding-right: 15px;
        padding-left: 15px;
    }
}

.modal {
    padding-bottom: 60px;
    margin-right: auto;
    margin-left: auto;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.03);
}

.header {
    display: flex;
    min-height: 70px;
    align-items: center;
    justify-content: space-between;
    padding-right: 27px;
    margin-bottom: 25px;
    background: #f8faff;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.title {
    margin: 0;
    color: #303e65;
    font-size: 18px;
    font-weight: 500;
}

.btnClose {
    display: flex;
    width: 15px;
    height: 15px;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: 0;
    background-color: transparent;

    &:hover {
        cursor: pointer;

        :global {
            animation: rotate360 0.5s linear;
        }
    }
}
