.root {
    display: flex;
    width: 100%;
    max-width: 704px;
    padding: 11px 32px 15px 24px;
    border: 1px solid #e5eaf5;
    background: #fff;
    border-radius: 15px;
}

.icon {
    margin-top: 6px;
}

.content {
    margin-left: 18px;
}

.title {
    margin: 0;
    font-size: 14px;
}

.message {
    color: #008b9c;
    font-size: 12px;
    font-weight: 400;
}
