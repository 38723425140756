.root {
    display: inline-flex;
    align-items: center;
    padding: 8px;
    border: 1px solid #e5eaf5;
    background-color: #008b9c;
    border-radius: 15px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.03);
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;

    &:hover {
        background-color: darken(#008b9c, 2%);
        cursor: pointer;

        .icon {
            margin-left: 0;
            transform: rotate(360deg);
            transition: transform 0.5s ease;
        }
    }

    &:disabled {
        background-color: darken(#008b9c, 3%);
        cursor: unset;
        opacity: 0.8;

        .icon {
            transform: rotate(0deg);
        }
    }
}

.icon {
    margin-right: 5px;
    transition: transform 0.5s ease;
}

.filterActive {
    background-color: #ef8400;

    &:hover {
        background-color: darken(#ef8400, 3%);
        cursor: pointer;

        .icon {
            margin-left: 0;
            transform: rotate(360deg);
            transition: transform 0.5s ease;
        }
    }
}
