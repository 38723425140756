@import "../../../../mixin";

.root {
    width: 100%;
}

.section {
    display: flex;
    padding: 43px 0;
    border-bottom: 1px solid #e5eaf5;
    margin: 0 47px 0 36px;

    @include media-custom(1300) {
        flex-direction: column;
    }

    @include media-custom(500) {
        margin: 0 15px;
    }
}

.sectionTitle {
    width: 50%;
    color: #008b9c;
    font-size: 18px;
    font-weight: 300;
    line-height: 21px;
}

.sectionContent {
    width: 100%;
}

.sectionNote {
    max-width: 332px;
    padding: 15px;
    color: #008b9c;
    font-size: 12px;
    line-height: 14px;
}

.flexContainer {
    display: flex;
    align-items: center;
    gap: 30px;

    @include mobile {
        flex-direction: column;
        align-items: flex-start;
        gap: 0;
    }
}

.field {
    width: 100%;
}

.alert {
    margin-bottom: 43px;
}

.location {
    max-width: 700px;
    border-bottom: 1px solid #e5eaf5;
    margin-bottom: 27px;
}

.securityBtnIcon {
    margin: 0;
    margin-right: 10px;
}

.btn {
    height: 48px;
    font-size: 14px;
}

.btnContainer {
    display: flex;
    width: 100%;
    justify-content: flex-end;

    @include mobile {
        display: block;
    }
}

.btnWithoutIcon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.select {
    width: 100%;
    max-width: 337px;
}

.avatarUploader {

    @include media-custom(400) {
        flex-direction: column;
    }
}

.schoolTextarea {
    width: 100%;
    max-width: 320px;
}
