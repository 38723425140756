.root {
    display: flex;
    flex-direction: column;

    &:hover {
        cursor: pointer;
    }
}

.error,
.inputName {
    margin-bottom: 6px;
    margin-left: 16px;
    color: #303e65;
    font-size: 13px;
    font-weight: 600;
}

.error {
    color: #eb5757;
}

.input {
    position: absolute;
    width: 0;
    height: 0;
    cursor: pointer;
    opacity: 0;
}

.content {
    display: flex;
    align-items: center;
}

.checkmark {
    position: relative;
    display: inline-block;
    width: 16px;
    min-width: 16px;
    height: 16px;
    margin-right: 15px;
    background-color: #99d9df;
    border-radius: 4px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.05), inset 0 5px 20px rgba(0, 0, 0, 0.03);

    &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 8px;
        height: 8px;
        background: transparent url("../../assets/icons/check.svg") no-repeat;
        transform: translate(-50%, -50%);
    }
}

.input:checked ~ .content .checkmark {
    &::before {
        content: "";
    }
}

.label {
    margin: 0;
    color: #008b9c;
    font-size: 12px;
}

.require {
    margin: 5px 0 0 3px;
    color: #eb5757;
    font-size: 17px;
}
