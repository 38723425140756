.root {
    max-width: 32px;

    :global {
        .CircularProgressbar-trail {
            stroke: none !important;
        }

        .CircularProgressbar-path {
            stroke: #3bb055 !important;
        }
    }
}

.avatar {
    img {
        object-fit: cover;
    }
}
