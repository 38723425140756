@import "../../../../mixin";

.container {
    width: 100%;
}

.currentTableFieldsCard,
.selectTableFieldsCard {
    display: flex;
    overflow: hidden;
    width: 100%;
    min-height: 300px;
    padding: 11px;

    @include mobile {
        padding: 0;
    }
}

.selectTableFieldsCard {
    position: fixed;
    top: 0;
    right: -376px;
    width: 376px;
    height: 100vh;
    flex-direction: column;
    background: #008b9c;
    overflow-y: auto;
    transition: 0.3s;

    @include mobile {
        display: none;
    }

    &::-webkit-scrollbar {
        width: 0;
    }

    &.active {
        right: 0;
    }
}

.sideHead {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 50px;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
}

.saveBtn {
    height: 36px;
    justify-content: space-between;
    padding-right: 15px;
    border: 1px solid #fff;
    margin-left: 10px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.05);
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;

    .icon {
        margin-right: 5px;
    }

    &:hover {
        background: #7a89b4;
    }
}

.closeBtn {
    position: absolute;
    top: 10px;
    right: 340px;
    padding: 5px;
    border-style: none;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
}
