.root {
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.input {
    position: absolute;
    width: 0;
    height: 0;
    cursor: pointer;
    opacity: 0;
}

.content {
    display: flex;
    align-items: flex-start;
}

.checkmark {
    position: relative;
    display: inline-block;
    width: 16px;
    min-width: 16px;
    height: 16px;
    margin-right: 15px;
    border-radius: 4px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.05), inset 0 5px 20px rgba(0, 0, 0, 0.03);

    &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.input ~ .content .checkmark {
    &::before {
        content: "+";
    }
}

.input:checked ~ .content .checkmark {
    &::before {
        content: "-";
        transform: translate(50%, 0%);
    }
}

.label {
    color: #fff;
}
