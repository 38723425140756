@import "../../../../mixin";

.root {
    width: calc(100% - 82px);
}

.contentContainer {
    display: flex;
    padding: 43px 0;
    border-bottom: 1px solid #e5eaf5;
    margin-right: 47px;
    margin-left: 36px;

    @include media-custom(1300) {
        flex-direction: column;
    }

    @include mobile {
        flex-direction: column;
        align-items: center;
        margin: 0 10px;
    }
}

.deleteBtn {
    align-self: center;
    margin-bottom: 20px;
}

.title {
    width: 50%;
    margin: 0;
    margin-bottom: 20px;
    color: #008b9c;
    font-size: 18px;
    font-weight: 300;
    line-height: 21px;

    @include mobile {
        width: 100%;
        text-align: center;
    }
}

.noteRight {
    width: 300px;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #008b9c;
    font-size: 12px;
    line-height: 14px;

    @include mobile {
        width: 100%;
        padding-left: 21px;
    }
}

.note {
    max-width: 710px;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #008b9c;
    font-size: 12px;
    line-height: 14px;
}

.noteTitle {
    margin: 0 0 5px;
    color: #303e65;
    font-size: 14px;
    font-weight: 600;
}

.content {
    display: flex;
    width: 100%;
    flex-direction: column;
    gap: 30px;

    @include mobile {
        display: flex;
        max-width: 337px;
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
}

.textArea {
    width: 100%;
    max-width: 710px;
}

.field {
    width: 100%;
    max-width: 337px;

    @include mobile {
        margin-bottom: 20px;
    }
}

.flexWrapp {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    gap: 30px;

    @include mobile {
        width: 100%;
        flex-direction: column;
        margin-bottom: 0;
        gap: 0;
    }
}

.btnTitle {
    width: 100%;
    max-width: 317px;
    color: #303e65;
    font-size: 12px;
    font-weight: 500;
}

.btnAdd {
    display: flex;
    align-items: center;
    gap: 8px;
}

.btn {
    width: 100%;
    max-width: 337px;
    margin: 37px 0 0;

    @include mobile {
        margin: 10px 0 0;
    }

    &Container {
        display: flex;
        width: 100%;
        max-width: 710px;
        justify-content: flex-end;

        @include mobile {
            justify-content: center;
            margin-top: 10px;
        }
    }
}

.totalTurnover {
    margin-left: 0;
}
