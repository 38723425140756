
.container {
    margin-top: 20px;
}

.btn {
    margin-top: 180px;
}

.line {
    position: relative;
    left: -64px;
    width: 522px;
    border-bottom: 1px solid #e5eaf5;
    margin: 35px 0 22px;
}
