.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 17px;
}

.wrapper {
    width: 100%;
}

.titleBtn {
    padding: 0;
    border: 0;
    margin: 0;
    background-color: transparent;
}

.title {
    margin-top: 0;
    margin-bottom: 2px;
    color: #22404d;
    font-size: 14px;
    font-weight: 500;
    line-height: 1;
}

.description {
    margin: 0;
    color: #008b9c;
    font-size: 12px;
    font-weight: 500;
    line-height: 1;
}

.date {
    color: #008b9c;
    font-size: 12px;
    font-weight: normal;
}

.wrapperFooter {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 10px;
}

.viewDetail {
    margin-left: auto;
}
