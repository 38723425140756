.root {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.icon {
    margin-right: 10px;
}

.title {
    margin: 0;
    word-break: break-all;
}

.emptyBLock {
    display: flex;
    min-height: 230px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-right: 75px;
    padding-left: 75px;
    border: 1px solid #e5eaf5;
    margin-bottom: 20px;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.05), inset 0 5px 20px rgba(0, 0, 0, 0.03);
}

.items {
    margin-bottom: 20px;
    word-break: break-all;
}

.linkIcon {
    margin-left: 25px;
}

.messageNo {
    margin: 10px 0 0;
    color: #008b9c;
    font-size: 12px;
    text-align: center;
}

.btnFullList {
    margin-top: auto;
}
