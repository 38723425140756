@import "src/mixin";

.root {
    display: flex;
    flex-direction: column;

    @include mobile {
        align-items: center;
    }
}

.textField {
    max-width: unset !important;
}

.btnSubmit {
    margin-left: auto;
}

.flexContainer {
    display: flex;
    align-items: center;
    gap: 30px;

    @include mobile {
        width: 100%;
        max-width: 337px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 0;
    }
}

.avatarUploader {
    margin-bottom: 20px;

    @include media-custom(650) {
        flex-direction: column;
    }
}
