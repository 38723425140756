@import "../../mixin";

.wrapper {
    width: calc(100% - 82px);
}

.root {
    width: 100%;
}

.section {
    display: flex;
    justify-content: center;
    padding: 43px 0;
    margin: 0 47px 0 36px;

    @include media-custom(1300) {
        flex-direction: column;
    }

    @include media-custom(500) {
        margin: 0 15px;
    }
}

.underSection {
    width: 100%;
    margin-top: 120px;

    @include mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.sectionContent {
    width: 100%;
    max-width: 750px;

    @include mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.flexContainer {
    display: flex;
    align-items: center;
    gap: 30px;

    @include mobile {
        width: 100%;
        max-width: 337px;
        flex-direction: column;
        align-items: flex-start;
        gap: 0;
    }
}

.btnCreate {
    display: flex;
    justify-content: flex-end;
    margin: 115px 130px 0 30px;
}
