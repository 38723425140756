@import "../../mixin";

.root {
    width: 100%;
}

.header {
    height: 214px;
    padding-top: 60px;
    padding-bottom: 30px;
    background: #f8faff;
}

.container {
    width: 100%;
    max-width: 710px;
    padding-right: 10px;
    padding-left: 10px;
    margin-right: auto;
    margin-left: auto;

    @include mobile {
        align-items: center;
    }
}

.miniContainer {
    width: 100%;
    max-width: 357px;
    padding-right: 10px;
    padding-left: 10px;
    margin-right: auto;
    margin-left: auto;
}

.recoverContainer {
    display: flex;
    width: 100%;
    max-width: 357px;
    flex-direction: column;
    padding-right: 10px;
    padding-left: 10px;
    margin-top: 50px;
    margin-right: auto;
    margin-left: auto;
}

.title {
    margin-bottom: 5px;
    font-size: 32px;
    font-weight: 300;
}

.newPassTitle {
    margin-bottom: 5px;
    font-size: 20px;
    font-weight: 300;
}

.miniTitle {
    margin: 0;
    font-size: 16px;
    font-weight: 300;
}

.step {
    margin: 0;
    font-size: 32px;
    font-weight: 300;
}

.subtitle {
    font-size: 14px;
}

.titleSignIn {
    composes: title;
    margin-bottom: 43px;
}

.accountMessage {
    color: #303e65;
    font-size: 12px;
    font-weight: 500;
    text-align: right;
}

.accountMessageLogIn {
    margin-top: 35px;
}

.forgotPassportMessage {
    composes: accountMessage;
    text-align: left;
}

.form {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 50px;
}

.formPersonalDetails {
    position: relative;
    top: -62px;
}

.btnLogIn {
    margin-top: 52px;
    margin-left: auto;
}

.btnSignUp {
    margin-left: auto;
}

.btnRecover {
    margin-top: 25px;
    margin-left: auto;
}

.iconRecover {
    margin-left: 5px;
}

.leftForm {
    width: 100%;
    max-width: 337px;
}

.rightForm {
    width: 100%;
    max-width: 337px;
    margin-left: 20px;

    @include mobile {
        margin: 0;
    }
}

.line {
    width: 100%;
    border-bottom: 1px solid #e5eaf5;
    margin: 10px 0 34px;
}

.linkDocument {
    color: #303e65;
}

.avatarUploader {
    margin-bottom: 20px;
}

.companyTypeContainer {
    width: 100%;
}

.companyType {
    display: flex;
    align-items: center;
    margin: 20px 0;

    @include mobile {
        flex-direction: column;
        align-items: center;
    }
}

.companyCheckboxContainer {
    display: flex;
    align-items: center;

    &:hover {
        cursor: pointer;
    }
}

.companyCard {
    display: flex;
    width: 276px;
    height: 60px;
    align-items: center;
    padding: 0 30px;
    border: 1px solid #e5eaf5;
    margin-left: 25px;
    border-radius: 10px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.05);
    color: #303e65;
    font-size: 16px;
    font-weight: 600;
    line-height: 19px;

    @include mobile {
        width: 220px;
    }
}

.companyDescription {
    width: 100%;
    max-width: 338px;
    margin: 0 0 0 30px;
    color: #008b9c;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;

    @include mobile {
        margin: 10px 0 0;
    }
}

.section {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;

    @include mobile {
        width: 100%;
        flex-direction: column;
        align-items: center;
    }
}

.sectionDescription {
    width: 100%;
    max-width: 322px;
    margin: 20px 0 0 10px;
    color: #008b9c;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;

    @include mobile {
        margin: 0 0 20px 10px;
    }
}

.parentalConfirmation {
    display: flex;
    justify-content: space-between;
    margin-bottom: 120px;

    @include mobile {
        display: block;
    }
}

.parentalConfirmationLabel {
    max-width: 330px;
    margin: 0 0 0 30px;
    color: #008b9c;
    font-size: 12px;

    @include mobile {
        margin: 20px 0;
    }
}

.confirmContent {
    width: 100%;
    max-width: 950px;
    padding: 10px;
    margin: 240px auto;
}

.AwaitContainer {
    display: flex;
    width: 100%;
    max-width: 950px;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    margin: 200px auto;
    text-align: center;

    img {
        width: 200px;
    }
}

.confirmContentSuc {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.confirmHeader {
    margin-top: 15px;
    color: #808080;
    font-weight: 400;
}

.minHeader {
    margin: 0;
    color: #808080;
    font-weight: 400;
}

.logoImg {
    width: 256px;
    margin-bottom: 20px;
}

.confirmText {
    display: block;
    margin-top: 40px;
    color: #808080;
    font-size: 25px;
    word-break: break-word;
}

.confirmTextError {
    color: #eb5757;
}

.btnEmail {
    padding: 15px 22px 17px;
    text-transform: none;
}

.confirmBtns {
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
    gap: 45px;
}
