@import "../../../../mixin";

.root {
    display: flex;
    width: 320px;
    min-height: 260px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px solid #e5eaf5;
    border-radius: 20px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.05);
    gap: 10px;

    @include mobile {
        min-width: 200px;
        max-width: 275px;
    }
}

.websiteURL {
    color: #285f74;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
}

.companyType {
    color: #285f74;
    font-size: 12px;
    font-weight: 400;
}

.name,
.school {
    color: #285f74;
    font-size: 16px;
    text-align: center;
}

.state {
    color: #285f74;
    font-size: 12px;
    text-align: center;
}

.name {
    font-weight: 700;
}

.school {
    margin-top: 10px;
}

.status {
    color: #285f74;
    font-size: 14px;
    font-weight: 500;
}

.companyLogo {
    margin: 10px 0;

    img {
        margin: 0 auto;
        object-fit: cover;
    }

    span {
        color: #285f74;
        font-size: 16px;
    }
}

.link {
    max-width: 100%;
    padding: 0 15px;
    color: #303e65;
    cursor: pointer;
    font-size: 16px;
    overflow-wrap: break-word;
    text-align: center;
    text-decoration: none;
    word-wrap: break-word;
}

.slider {
    min-width: 100%;
    max-width: 320px;
    margin: 10px 0;

    @include mobile {
        min-width: 200px;
        max-width: 275px;
    }

    ul {
        position: static;
    }
}

.sliderBtn {
    z-index: 10;
    background-color: #fff !important;

    &::before {
        color: #285f74 !important;
    }
}

.linkContainer {
    display: flex !important;
    justify-content: center;
}

.descContainer {
    display: flex !important;
    width: 200px !important;
    min-height: 260px;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    word-break: break-word;
}
