@import "../../../mixin";

.container {
    display: flex;
    max-width: 704px;
    flex-direction: column;
    margin: 38px auto 0;
}

.linkDocument {
    color: #303e65;
    cursor: pointer;
    text-decoration: underline;
}

.attentionCard {
    display: flex;
    width: 100%;
    padding: 11px 30px 15px 22px;
    border: 1px solid #e5eaf5;
    margin-bottom: 15px;
    background-color: #fff;
    border-radius: 15px;

    @include mobile {
        display: block;
    }
}

.warningImage {
    width: 24px;
    height: 24px;
    margin: 14px 18px 14px 0;
}

.cardTitle {
    margin: 0 0 5px;
    color: #303e65;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
}

.cardDescription {
    margin: 0;
    color: #008b9c;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
}

.section {
    display: flex;
    justify-content: space-between;

    @include mobile {
        flex-direction: column;
        align-items: center;
    }
}

.sectionCenter {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.leftForm,
.rightForm {
    width: 100%;
    max-width: 337px;
}

.rightForm {
    margin-left: 30px;

    @include mobile {
        margin-left: 0;
    }
}

.line {
    width: 100%;
    border-bottom: 1px solid #e5eaf5;
    margin: 30px 0;
}

.btn {
    margin-top: 50px;
    margin-left: auto;
}

.btnLeft {
    margin-top: 70px;
}

.minContainer {
    display: flex;
    max-width: 398px;
    flex-direction: column;
    margin-top: 25px;
}

.profiles {
    width: 100%;
    margin-bottom: 20px;
}

.profileBlock {
    display: flex;
    align-items: center;
}

.profilesItem {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    @include media-custom(500) {
        display: block;
    }
}

.profilesStatus {
    margin-right: 22px;
    margin-left: auto;
    color: #22404d;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
}

.profilesName {
    margin: 0 9px 0 10px;
    color: #303e65;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
}

.profilesButton {
    margin-right: 22px;
    margin-left: auto;

    @include media-custom(500) {
        margin-left: 40px;
    }
}

.btnRemove {
    color: #eb5757;
}

.searchSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;

    @include media-custom(500) {
        display: block;
    }
}

.sectionText {
    min-width: 50%;
    margin: 0;
    color: #008b9c;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;

    @include media-custom(500) {
        margin-bottom: 5px;
    }
}

.inviteSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.inviteSectionBtn {
    margin-left: 20px;
}

.selectField {
    width: 337px;
}

.cardSection {
    width: 100%;
    height: 196px;
    padding: 11px;
    margin-bottom: 40px;
    background: #fff;
    border-radius: 20px;
    box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.05), inset 0 5px 20px rgba(0, 0, 0, 0.03);
}

.currentTableFieldsCard,
.selectTableFieldsCard {
    width: 100%;
    height: 200px;
    padding: 11px;
    background: #fff;
    border-radius: 20px;
    box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.05), inset 0 5px 20px rgba(0, 0, 0, 0.03);
}

.selectTableFieldsCard {
    overflow: hidden;
    height: 252px;
}

.text {
    margin: 0 0 5px;
    color: #008b9c;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
}

.btnWrapper {
    margin-top: 10px;
    margin-left: 10px;
}

.inputField {
    width: 100%;
    max-width: 354px;
    height: 40px;
    margin: 0 20px 6px 0;
    background: #f7f9ff;
    border-radius: 10px;
    box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.05), inset 0 5px 20px rgba(0, 0, 0, 0.03);
}

.deleteBtn {
    padding: 5px;
    border: 0;
    background: none;
    box-shadow: none;

    &:hover {
        background: none;
    }
}

.item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 7px 30px 7px 0;
}

.name {
    margin-left: 10px;
    color: #303e65;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
}

.school {
    margin-left: 10px;
    color: #303e65;
    font-size: 12px;
    line-height: 14px;
}

.avatar {
    margin-right: 9px;
    margin-left: 17px;
}

.selectFieldOption {
    display: flex;
    align-items: center;
}

.cancelBtn {
    padding-left: 50px;
    margin-right: 5px;
    margin-bottom: 5px;
    text-align: center;

    &:hover {
        background: #f4fdf4;
        color: #22404d;
    }
}

.errorMessage {
    padding: 5px;
    margin-top: 10px;
    color: #eb5757;
    font-size: 14px;
    font-weight: 600;
}

.userAvatar {
    img {
        object-fit: cover;
    }
}
