@import "../../../../../mixin";

.root {
    display: flex;
    width: 100%;
    flex-direction: column;
}

.section {
    display: flex;
    padding: 43px 0;
    border-bottom: 1px solid #e5eaf5;

    @include media-custom(1300) {
        flex-direction: column;
    }

    @include mobile {
        align-items: center;
    }
}

.title {
    padding: 0;
    color: #008b9c;
    font-size: 14px;
    font-weight: 600;
}

.sectionTitle {
    width: 50%;
    margin-bottom: 30px;
    color: #008b9c;
    font-size: 18px;
    font-weight: 300;
    line-height: 21px;

    @include mobile {
        width: 100%;
        text-align: center;
    }
}

.deadline {
    color: #008b9c;
    font-size: 18px;
    font-weight: 600;
}

.description {
    color: #008b9c;
    font-size: 12px;

    @include mobile {
        max-width: 337px;
        margin: 0 0 30px;

        p {
            margin: 0;
        }
    }

    p {
        margin: 10px 0 0;
        color: #303e65;
        font-size: 1.1em;
        font-weight: 600;
    }
}

.sectionContent {
    width: 100%;

    @include mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

.sectionNote {
    max-width: 332px;
    padding: 15px;
    color: #008b9c;
    font-size: 12px;
    line-height: 14px;
}

.flexContainer {
    display: flex;
    margin-bottom: 15px;
    gap: 30px;

    @include mobile {
        width: 100%;
        flex-direction: column;
        align-items: center;
        gap: 0;
    }
}

.field {
    margin-bottom: 20px;
}

.btnContainer {
    display: flex;
    max-width: 720px;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 20px;
    gap: 10px;

    @include mobile {
        width: 100%;
        align-items: center;
        margin-top: 10px;
        gap: 0;
    }
}

.btn {
    display: flex;
    width: 150px;
    justify-content: center;

    @include mobile {
        width: 100% !important;
        max-width: 337px;
        margin-bottom: 10px;
    }
}

.checkbox {
    max-width: 320px;
    margin-bottom: 20px;
}

.submittedContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
    font-size: 20px;
    font-weight: bold;
    gap: 30px;
    text-align: center;
}

.calculated:disabled {
    background-color: #7096a8;
    color: #fff;
}
