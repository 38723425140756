.root {
    position: relative;
    display: flex;
    min-width: 3px;
    align-items: flex-start;
    justify-content: flex-end;
}

.btn {
    border: 0;
    background-color: #fff;
    outline: none;

    &:hover {
        cursor: pointer;
    }
}

.popup {
    position: absolute;
    z-index: 999;
    top: 5px;
    right: 25px;
}

.opened {
    display: block;
}

.closed {
    display: none;
}

.options {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin: 0;
    list-style-type: none;

    .option {
        padding: 5px;

        &:hover {
            background-color: darken(#fff, 5);
            cursor: pointer;
        }
    }
}
