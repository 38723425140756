.root {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: 1px solid #f2f6ff;
    background-color: #f2f6ff;
    border-radius: 20px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.12);
}

.titleContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title {
    display: inline-flex;
    align-items: center;
    margin: 0 0 5px;
    font-size: 18px;
    word-break: break-word;
}

.newsChip {
    margin-right: 5px;
}

.feedInfo {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}

.feedInfoItem {
    display: inline-flex;
    align-items: center;
    color: #008b9c;
    font-size: 12px;
}

.feedInfoItemEndDate {
    composes: feedInfoItem;
    margin-left: auto;
}

.feedInfoItemLabel {
    margin-right: 5px;
}

.location {
    margin: 0 0 5px;
}

.description {
    flex: 1;
    margin: 15px 0 0;
    color: #008b9c;
    font-size: 13px;
    word-break: break-all;
}

.externalLink {
    margin-top: 25px;
    margin-left: auto;
    color: #303e65;
    font-size: 12px;
    font-weight: 700;
    text-decoration: none;
}

.externalLinkIcon {
    margin-left: 5px;
}

.actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.btnRemove {
    margin-top: 25px;
    color: #eb5757;
}
