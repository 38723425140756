.root {
    display: flex;
    width: 245px;
    height: 205px;
    flex-direction: column;
    align-items: center;
    background: #e5eaf5 no-repeat 0;
    border-radius: 10%;
}

.header {
    margin: 20px 0 6px;
    font-size: 1em;
    text-align: center;
}

.main {
    height: 145px;
}

.mainNumber {
    margin: 28px 0 0;
    font-size: 48px;
    text-align: center;
    white-space: nowrap;
}

.link {
    display: flex;
    width: 210px;
    height: 32px;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    background-color: #22404d;
    border-radius: 10px;
    color: #fff;
    font-size: 12px;
    text-decoration: none;
}

.list {
    border: 0;
    margin-bottom: 11px;
    background-color: #e5eaf5;
    color: #008b9c;
    font-size: 0.75em;
    outline: none;
}

.footerContainer {
    display: flex;
    width: 100%;
    height: 60px;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #bcc9e4;
}

.statusText {
    margin: 0;
    color: #303e65;
    font-size: 0.85em;
    font-weight: bold;
}
