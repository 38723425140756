@import "../../../../mixin";

.root {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 272px;
    width: 263px;
    background: #00a0af;
    border-radius: 10px;

    @include tablet {
        left: 75px;
    }
}

.fixedRoot {
    position: fixed;
    top: 5%;
}

.navItem {
    display: flex;
    height: 42px;
    align-items: center;
    padding-right: 11px;
    padding-left: 11px;
    margin-bottom: 5px;
    border-radius: 10px;
    text-decoration: none;

    @include tablet {
        justify-content: center;
    }

    @include mobile {
        justify-content: flex-start;
    }

    &:hover {
        background: #22404d;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.navItemActive {
    background-color: #22404d;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.05);

    &:hover {
        background-color: #22404d;
    }
}

.navItemName {
    margin-left: 25px;
    color: #fff;
    font-size: 16px;
}
