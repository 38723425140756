@import "../../../mixin";

.root {
    display: flex;
    min-width: 225px;
    height: 155px;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background-color: #e5eaf5;
    border-radius: 10%;

    @include mobile {
        margin: 20px;
    }
}

.header {
    margin: 30px 0 6px;
    font-size: 1em;
}

.existNumber {
    margin: 0;
    margin-bottom: 7px;
    font-size: 3em;
}
