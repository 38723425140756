.root {
    position: relative;
    display: block;
    width: 100%;
}

.btn {
    position: absolute;
    top: 3px;
    left: 6px;
    width: 24px;
    height: 24px;
    padding: 0;
    border: 0;
    background: none;
    cursor: pointer;
    outline: none;
    transition: transform 0.3s ease;

    &:hover {
        transform: scale(1.1);
    }
}

.btnClean {
    position: absolute;
    right: 0;
    left: unset;
}

.input {
    width: 100%;
    height: 30px;
    box-sizing: border-box;
    padding: 0 10px 0 35px;
    padding-right: 30px;
    border: 0;
    background-color: #f8faff;
    border-radius: 15px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.05), inset 0 5px 20px rgba(0, 0, 0, 0.03);
    color: #303e65;
    font-weight: 500;
    outline: none;

    &::placeholder {
        color: #008b9c;
        font-weight: 300;
        line-height: 14px;
        size: 12px;
    }

    &:focus {
        box-shadow: 2px 1px 9px 2px rgba(74, 82, 87, 0.18);
    }

    &:disabled {
        background-color: #e9e9e9;
    }
}
