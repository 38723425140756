@import "../../mixin";

.title {
    color: #303e65;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
}

.value {
    color: #303e65;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 38px;
    text-align: center;
}

.root {
    display: flex;
    width: 100%;
    max-width: 245px;
    height: 105px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #e5eaf5;
    border-radius: 20px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.05);

    @include media-custom(1200) {
        margin-bottom: 20px;
    }
}
