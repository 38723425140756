@import "../../../../mixin";

.root {
    width: calc(100% - 82px);
}

.subHeader {
    display: flex;
    width: 100%;
    min-height: 62px;
    align-items: center;
    justify-content: space-between;
    padding: 0 48px 0 38px;
    background: #f8faff;

    @include media-custom(500) {
        display: block;
        padding: 10px 48px 10px 38px;
    }
}

.userCounter {
    color: #303e65;
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;

    @include media-custom(500) {
        margin: 5px;
    }
}

.listButtons {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
}

.listButtonsItem {
    position: relative;
    top: -5px;
    margin-right: 30px;
}

.date {
    display: flex;
    min-width: 300px;
    align-items: center;
    justify-content: space-between;
}
