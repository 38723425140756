@import "mixin";

*,
::after,
::before {
    box-sizing: border-box;
}

body {
    padding: 0;
    margin: 0;
    color: #303e65;
    font-family: "Work Sans", Arial, sans-serif;
}

img {
    max-width: 100%;
    height: auto;
}

svg {
    flex: 1; // iphone fix
}

.root {
    display: flex;
    min-height: 100vh;
    background-color: #fff;
}

.teacherMode {
    position: relative;
    padding-top: 30px;
}

@keyframes :global(rotate360) {
    100% {
        transform: rotate(360deg);
    }
}

:global {
    .container {
        width: 100%;
        padding-right: 15px;
        padding-left: 35px;

        @include tablet {
            padding-right: 10px;
            padding-left: 15px;
        }

        @include mobile {
            padding-right: 5px;
            padding-left: 10px;
        }
    }
}
