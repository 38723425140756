
.btnActive {
    display: flex;
    width: 15px;
    align-items: center;
    justify-content: center;
    padding: 2px 5px;
    border: 0;
    background-color: transparent;
    transition: transform 200ms ease;

    :hover {
        cursor: pointer;
        transform: scale(1.1);
        transition: transform 200ms ease;
    }
}

.btnItem {
    border: 0;
    margin-top: 5px;
    margin-bottom: 5px;
    background-color: transparent;
    transition: transform 200ms ease;

    &:hover {
        cursor: pointer;
        transform: scale(1.1);
        transition: transform 200ms ease;
    }

    &:disabled:hover {
        cursor: auto;
        transform: scale(1);
    }
}

.tooltip {
    border: 1px solid #f3f1f1 !important;
    background-color: #fff !important;
    border-radius: 10px !important;
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.15);
    opacity: 1 !important;

    &::after {
        border-top-color: #fff !important;
        border-left-color: #fff !important;
    }
}

.bthList {
    display: flex;
    flex-direction: column;
}
