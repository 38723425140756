@import "../../../../mixin";

.root {
    width: 100%;
}

.innerWrapper {
    padding: 0 47px 57px 35px;
}

.separator {
    width: 100%;
    border-top: 1px solid #e5eaf5;
    margin-bottom: 26px;
}

.editorWrapper {
    overflow: hidden;
    width: 100%;
    max-width: 706px;
    height: 503px;
    padding: 17px 28px 10px 21px;
    background-color: #f8faff;
    border-radius: 15px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.05), inset 0 5px 20px rgba(0, 0, 0, 0.03);
}

.saveBtnWrapper {
    display: flex;
    justify-content: flex-end;
}

.title {
    color: #008b9c;
    font-size: 18px;
    font-weight: 300;

    @include tablet {
        margin-bottom: 13px;
    }
}

.emailBody {
    display: flex;
    justify-content: space-between;
    margin-bottom: 45px;

    @include tablet {
        flex-direction: column;
        align-items: flex-start;
    }
}

.templateName {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 42px;

    @include tablet {
        flex-direction: column;
        align-items: flex-start;
    }
}

.templateNameInput {
    width: 100%;
    max-width: 706px;
    height: 46px;
    padding: 14px 21px 16px;
    border: 0;
    background: #f8faff;
    border-radius: 15px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.05), inset 0 5px 20px rgba(0, 0, 0, 0.03);
    color: #303e65;
    font-size: 14px;
    font-weight: 300;
    outline: none;
}
