@import "../../../mixin";

.root {
    display: flex;
    width: 100%;
}

.banner {
    width: 100%;
    max-width: 345px;
    background: repeat-y top/contain url("../../../assets/images/bg-banner-auth-new.png");
    background-position-x: 0;

    @include tablet {
        display: none;
    }
}

.projectName {
    display: inline-block;
    margin-top: 23px;
    margin-left: 30px;
    color: #f2f2f2;
    font-family: Poppins, Arial, sans-serif;
    font-weight: 600;
    text-decoration: none;
    transition: transform 300ms ease;

    &:hover {
        transform: scale(1.1);
    }
}
