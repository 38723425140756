.root {
    position: absolute;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transition-property: all;
    transition-timing-function: ease;
}

.stepTitle {
    position: absolute;
    z-index: -1;
    bottom: 18px;
    width: 60px;
    color: #008b9c;
    font-size: 0.65em;
    font-weight: 400;
    text-align: center;
}

.popup {
    position: absolute;
    z-index: 5;
    bottom: 20px;
    display: none;
    width: 180px;
    height: 90px;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #e5eaf5;
    background-color: #fff;
    border-radius: 10px;
    transform: translateX(-85px);

    &::after {
        position: absolute;
        bottom: -15px;
        left: 73px;
        width: 0;
        height: 0;
        border-top: 15px solid #fff;
        border-right: 15px solid transparent;
        border-left: 15px solid transparent;
        content: "";
    }

    &:hover {
        display: flex;
    }
}

.popupText {
    margin-top: 25px;
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 500;
}

.btnContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
}

.firstBtnIcon {
    transform: rotate(180deg);
}

.popupBtn {
    &:hover {
        .firstBtnIcon {
            transform: rotate(540deg);
        }
    }

    &:disabled {
        background-color: rgba(0, 0, 0, 0);

        .firstBtnIcon {
            transform: rotate(180deg);
        }
    }
}

.pageNumber {
    margin: 0 10px;
    color: #008b9c;
    font-size: 13px;
}

.arrow {
    display: block;
    width: 25px;
}

.pagePagination {
    align-self: center;
    font-size: 0.85em;
    font-weight: 300;
}

.underStep {
    position: absolute;
    top: 20px;
    display: flex;
}

.stepTitleFulfilled {
    position: absolute;
    z-index: 3;
    bottom: 18px;
    display: flex;
    width: 18px;
    height: 18px;
    align-items: center;
    justify-content: center;
    background-color: #3bb055;
    border-radius: 100%;
}

.strokeImg {
    height: 8px;
}

.pendingStep {
    position: absolute;
    z-index: 3;
    width: 8px;
    height: 8px;
    background-color: #46b17b;
    border-radius: 100%;

    &:hover > .popup {
        display: flex;
    }
}

.fulfilledStep {
    z-index: 3;
    width: 8px;
    height: 8px;
    background-color: #92e757;
    border-radius: 100%;
}
