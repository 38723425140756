.root {
    display: block;
    cursor: pointer;
}

.radio {
    display: none;
}

.radioWrapper {
    position: relative;
    display: block;
    width: 20px;
    height: 20px;
    margin-top: 5px;
    background: #f8faff;
    border-radius: 10px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.05), inset 0 5px 20px rgba(0, 0, 0, 0.03);
}

.radioWrapper::before {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 8px;
    height: 8px;
    background: #22404d;
    border-radius: 50%;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.05);
    content: "";
    opacity: 0;
    transform: translate(-50%, -50%);
    transition: 0.2s;
}

.radio:checked + .radioWrapper::before {
    opacity: 1;
}

.error {
    margin-bottom: 6px;
    color: #eb5757;
    font-size: 13px;
    font-weight: 600;
}
