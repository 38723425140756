@import "../../../../mixin";

.root {
    width: calc(100% - 282px);

    @include tablet {
        width: calc(100% - 82px);
    }
}

.headerContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;

    @include mobile {
        flex-direction: column;
        padding: 10px;
        gap: 10px;
    }
}

.header {
    display: flex;

    @include media-custom(1200) {
        flex-direction: column;
    }
}

.checkbox {
    margin-right: 5px;

    &Title {
        padding-left: 15px;
        margin-bottom: 5px;
        color: #008b9c;
        font-size: 0.81em;
    }

    &Container {
        display: flex;
        width: 300px;
        justify-content: space-between;
        margin-bottom: 10px;

        @include mobile {
            width: auto;
            flex-wrap: wrap;
            gap: 5px;
        }
    }
}

.searchContainer {
    display: flex;
    align-items: center;
    margin-left: 190px;

    @include media-custom(1392) {
        margin-left: 90px;
    }

    @include media-custom(1239) {
        margin-left: 20px;
    }

    @include media-custom(1200) {
        margin-left: 0;
    }

    @include mobile {
        flex-direction: column;
    }
}

.filterBtn {
    display: flex;
    width: 150px;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    background-color: #008b9c;

    @include mobile {
        width: 100%;
        margin-top: 10px;
    }
}

.table {
    &TitleName {
        padding-left: 20px;
    }

    &NameContainer {
        display: flex;
        min-width: 250px;
        align-items: center;
        padding-left: 20px;
        word-break: break-word;
    }

    &Name {
        margin-left: 10px;
        color: #303e65;
        font-size: 0.88em;
        font-weight: 600;
    }

    &Type {
        min-width: 200px;
    }

    &Company {
        min-width: 100px;
    }

    &DateContainer {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        padding-right: 20px;
    }

    &Type,
    &Company,
    &Date {
        font-size: 0.85em;
    }
}

.selectCompany {
    width: 246px;
    border: 1px solid #e5eaf5;
    margin-right: 15px;
    margin-left: 15px;
    border-radius: 10px;

    :global {
        .select__control {
            border: 0;
            background-color: transparent;

            &:hover {
                cursor: pointer;

                svg {
                    :global {
                        animation: rotate360 0.8s linear;
                    }
                }
            }
        }

        .select__control--is-focused {
            border-radius: 10px;
        }

        .select__placeholder {
            padding-left: 10px;
        }

        .select__option {
            padding: 15px;
            background: none;
            border-radius: 15px;
            color: #008b9c;
            cursor: pointer;

            span {
                color: #008b9c;
            }
        }

        .select__value-container {
            height: 38px;
            padding: 0;
        }

        .select__indicator-separator {
            display: none;
        }

        .select__option--is-focused {
            background-color: #f8faff;
        }

        .select__option--is-selected {
            border-radius: 0;
        }
    }

    &Option {
        display: flex;
        justify-content: flex-start;

        &Icon {
            margin-top: 3px;
            margin-left: 10px;
        }

        &Text {
            margin-left: 10px;

            h3 {
                margin: 0;
                font-size: 0.85em;
                font-weight: 500;
            }

            p {
                margin: 0;
                color: #008b9c;
                font-size: 0.65em;
            }
        }
    }
}

.subHeader {
    display: flex;
    width: 100%;
    min-height: 62px;
    align-items: center;
    justify-content: space-between;
    padding: 10px 48px 10px 38px;
    background: #f8faff;

    @include mobile {
        flex-direction: column;
        align-items: center;
    }
}

.downloadContent {
    display: flex;
    align-items: center;
    color: #303e65;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;

    @include media-custom(600) {
        display: block;
    }
}
