@import "../../../../mixin";

.root {
    width: calc(100% - 282px);

    @include tablet {
        width: calc(100% - 82px);
    }
}

.subHeader {
    display: flex;
    width: 100%;
    min-height: 62px;
    align-items: center;
    justify-content: space-between;
    padding: 0 48px 0 38px;
    background: #f8faff;
}

.userCounter {
    color: #303e65;
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
}

.downloadContent {
    display: flex;
    align-items: center;
    color: #303e65;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;

    @include media-custom(600) {
        display: block;
    }
}

.rootLoading {
    transform: rotate(0deg);

    :global {
        animation: rotate360 0.5s linear infinite;
    }
}

.reportCards {
    display: grid;
    align-items: center;
    padding: 20px 38px;
    grid-column-gap: 15px;
    grid-template-columns: repeat(4, 0.5fr);
    justify-items: center;

    @include media-custom(1200) {
        grid-template-columns: repeat(3, 1fr);
    }

    @include media-custom(850) {
        grid-template-columns: repeat(2, 1fr);
    }

    @include media-custom(650) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.miniHeader {

    @include mobile {
        display: flex;
        flex-direction: column;
        padding: 10px;
        gap: 10px;
    }
}

.miniHeaderContent {
    display: flex;
    align-items: center;

    @include mobile {
        flex-direction: column;
    }
}

.search {
    width: 100%;
    max-width: 440px;
    margin: 0 10px;
}

.listButtons {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
}

.listButtonsItem {
    position: relative;
    top: -5px;
    margin-right: 20px;
}

.filterBtn {
    display: flex;
    min-width: 150px;
    align-items: center;
    justify-content: center;

    @include mobile {
        width: 100%;
        min-width: auto;
        margin-top: 10px;
    }
}
