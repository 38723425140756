@import "../../../../mixin";

.root {
    width: calc(100% - 282px);

    @include tablet {
        width: calc(100% - 82px);
    }
}

.header {
    display: flex;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #f8faff;

    @include tablet {
        flex-wrap: wrap;
    }
}

.companyTitle {
    margin: 0;
    font-size: 24px;
    font-weight: 300;
    line-height: 1;
}

.companyType {
    margin: 0;
    font-size: 14px;
    font-weight: 600;
    line-height: 1;
}

.tag {
    padding: 7px 14px;
    margin-left: 120px;
    border-radius: 10px;
    color: #22404d;
    font-size: 12px;

    @include tablet {
        margin-left: auto;
    }

    @include mobile {
        display: none;
    }
}

.branch {
    color: #22404d;
    font-size: 12px;

    @include tablet {
        margin-left: auto;
    }

    @include mobile {
        display: none;
    }
}

.statusWrapper {
    display: flex;
}

.tagCompanyStatus {
    composes: tag;
    display: flex;
    align-items: center;
    margin-right: 20px;
    color: #46b17b;

    @include mobile {
        width: 100%;
        margin-top: 20px;
    }
}

.tagStatusText {
    margin-left: 10px;
}

.btnSubmitReview {
    padding: 12px;
    border: 1px solid #e5eaf5;
    background-color: #22404d !important;
    color: #fff !important;

    &:hover {
        background-color: darken(#22404d, 2%) !important;
    }

    &:disabled {
        background-color: #ccc !important;
        color: #303e65;
    }
}

.user {
    display: flex;
    align-items: center;
    margin-left: auto;

    @include tablet {
        width: 100%;
        margin-top: 30px;
    }
}

.userInfo {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
}

.userFullName {
    font-size: 14px;
    font-weight: 500;
}

.userType {
    color: #008b9c;
    font-size: 10px;
}

.userProfileAdd {
    margin-left: 77px;

    @include tablet {
        margin-left: auto;
    }
}

.usersCards {
    display: grid;
    padding-top: 5px;
    padding-bottom: 30px;
    background-color: #f8faff;
    gap: 20px;
    grid-template-columns: repeat(3, 1fr);

    @include mobile {
        grid-template-columns: 1fr;
    }
}

.usersCard {
    width: 100%;

    &:last-child {
        margin-right: 0;
    }

    :global {
        .uploader-wrapper {
            width: 100%;
            height: 100%;
        }
    }
}

.emptyBlockFile {
    display: flex;
    height: 100px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #f2f5fb;
    border-radius: 20px;
    box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.05), inset 0 5px 20px rgba(0, 0, 0, 0.03);

    :global {
        .uploader-wrapper {
            display: flex;
            height: auto;
            flex-direction: column;
            background-color: transparent;
            box-shadow: none;
        }

        .uploader-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-left: 0;
        }

        .uploader-title {
            margin-top: 5px;
            font-size: 12px;
            font-weight: 400;
        }

        .uploader-btn-add {
            margin-right: 0;
        }
    }
}

.userAvatar {
    img {
        object-fit: cover;
    }
}
