.root {
    display: flex;
    flex-direction: column;
}

.textField {
    max-width: unset !important;
}

.btnSubmit {
    margin-left: auto;
}

.permissionsSection {
  
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 8px; 
    background-color: #F8FAFF;
    display: flex; 
    flex-direction: column; 
    gap: 12px; 
  }
  