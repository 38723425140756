.policyLinkContainer {
    position: absolute;
    top: 15px;
    right: 50px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    margin-left: 82px;
    color: #285f74;
    gap: 0 15px;
}

.impintLink {
    padding-right: 15px;
    border-right: 1px solid #285f74;
    color: #285f74;
    font-size: 13px;
    text-decoration: none;
}

.poweredLink {
    margin: 0;
    color: #285f74;
    font-size: 13px;
    font-weight: 500;
    text-decoration: none;
}
