.root {
    display: flex;
    flex-direction: column;
}

.textField {
    max-width: unset !important;
}

.btnSubmit {
    width: auto;
    margin-top: 40px;
    margin-left: auto;
}

.fromSchoolChip {
    display: inline-flex;
}

.iconArrowMerge {
    margin: 0 auto;
    transform: rotate(180deg);
}

.selectField {
    width: 100%;
    max-width: unset;
}

.selectFieldOption {
    display: flex;
    align-items: center;
}

.uriTip {
    padding: 5px;
    margin: 0 0 25px 5px;
    font-size: 12px;
}
