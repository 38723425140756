@import "../../../../mixin";

.root {
    width: 100%;
    padding-bottom: 100px;
    margin: 0 auto;
}

.main {
    width: 860px;
    margin: 50px auto 0;
}

.basicInfo,
.emploee,
.expert,
.responsibleTeacher,
.documentation,
.contact,
.schoolTeacher,
.descriptionActivity,
.adminComment {
    position: relative;
    display: flex;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-left: 35px;
}

.businessActivity,
.businessIdea {
    position: relative;
    display: flex;
    margin-top: 42px;
    margin-left: 35px;
}

.expert,
.responsibleTeacher {
    align-items: center;

    &::after {
        bottom: -20px;
    }
}

.adminComment {
    width: 100%;
    margin-bottom: 50px;

    &::after {
        content: none;
    }
}

.basicInfoMain,
.emploeeMain,
.expertMain,
.responsibleTeacherMain,
.documentationMain,
.contactMain,
.schoolTeacherMain,
.businessActivityMain,
.businessIdeaMain,
.descriptionActivityMain,
.adminCommentMain {
    width: 510px;
    margin-left: 95px;
}

.sectionWrapper {
    width: 510px;
    flex-direction: column;
    margin-left: 95px;
}

.totalAmount {
    display: flex;
    max-width: 241px;
    margin-top: 20px;
    margin-left: 241px;
}

.adminCommentMain {
    display: flex;
    width: 100%;
    align-items: flex-end;
    margin-left: 0;
}

.adminCommentTextArea {
    width: 100%;
    margin-right: 30px;
}

.readOnlyInput {
    margin-bottom: 32px;

    &:last-child {
        margin-bottom: 0;
    }
}

.readOnlyLabel {
    margin: 0;
    margin-bottom: 6px;
    margin-left: 10px;
    color: #008b9c;
    font-size: 0.85em;
}

.readOnlyText {
    display: flex;
    width: 337px;
    height: 48px;
    align-items: center;
    justify-content: flex-start;
    padding-left: 14px;
    margin: 0;
    border-radius: 10px;
    color: #303e65;
    font-size: 0.9em;
    font-weight: 500;
}

.readOnlyTextArea {
    display: flex;
    width: 459px;
    justify-content: flex-start;
    padding: 16px 17px 70px 21px;
    margin: 6px 0 0;
    border-radius: 15px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.05), inset 0 5px 20px rgba(0, 0, 0, 0.03);
    color: #303e65;
    font-size: 0.89em;
    font-weight: 500;
    word-break: break-all;
}

.emploeeContainer {
    display: flex;
    align-items: center;
    margin-bottom: 23px;
}

.employeesWrapper {
    display: flex;
    align-items: center;
}

.avatar {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    object-fit: fill;
}

.name {
    width: 150px;
    margin-left: 17px;
    color: #303e65;
    font-size: 0.85em;
    font-weight: 600;
}

.status {
    margin-left: 60px;
    color: #3bb055;
    font-size: 0.85em;
    font-weight: 500;
}

.expertContainer {
    display: flex;
    align-items: center;
}

.responsibleTeacherContainer {
    display: flex;
    align-items: center;
}

.documentationContainer {
    display: flex;
    align-items: center;
    margin-bottom: 26px;
}

.documentIcon {
    width: 38px;
    height: 38px;
    padding: 6px;
    border: 1px solid #e5eaf5;
    border-radius: 10px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.05);
}

.documentationNameContainer {
    margin-left: 8px;
}

.documentTitle {
    margin: 0;
    font-size: 0.88em;
    font-weight: 600;
}

.documentName {
    margin: 0;
    color: #008b9c;
    font-size: 0.75em;
}

.checkboxContainer {
    min-width: 50px;
}

.goBackBtn {
    display: flex;
    justify-content: flex-end;
    padding-right: 100px;

    &Icon {
        margin-right: 15px;
        transform: rotate(180deg);
    }

    &:hover {
        .goBackBtnIcon {
            transform: rotate(540deg);
        }
    }
}

.footer {
    display: flex;
    width: 860px;
    justify-content: space-between;
    margin-left: 35px;
}

.checkbox {
    position: relative;
    display: block;
    min-width: 24px;
    min-height: 24px;
    border: 1px solid #285f74;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: none;

    &::before {
        position: absolute;
        display: flex;
        width: 24px;
        height: 24px;
        justify-content: center;
        background: #285f74 url("../../../../assets/icons/checkWhite.svg") no-repeat center;
        border-radius: 100%;
    }
}

.checkboxLabel {
    font-size: 1.15em;
    font-weight: 300;
}

.wrapper {
    display: flex;
    width: 333px;
    height: 80px;
    align-items: center;
    background: #fff;
    border-radius: 20px;
    box-shadow: none;
    cursor: pointer;

    @include mobile {
        width: 100%;
        max-width: 330px;
    }
}

.flexWrapp {
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    gap: 30px;

    @include mobile {
        width: 100%;
        flex-direction: column;
        margin-bottom: 0;
        gap: 0;
    }
}
