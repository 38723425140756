.root {
    display: flex;
    width: 245px;
    height: 205px;
    flex-direction: column;
    align-items: center;
    background: #e5eaf5 url("../../../assets/icons/waves.svg") no-repeat 0 33%;
    border-radius: 10%;
}

.header {
    margin: 9px 0 6px;
    font-size: 17px;
    font-weight: 600;
}

.main {
    height: 125px;
    text-align: center;
}

.options {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.currentOption {
    margin-right: 4px;
    color: #008b9c;
    font-size: 12px;
}

.list {
    border: 0;
    margin-bottom: 11px;
    background-color: #e5eaf5;
    color: #008b9c;
    font-size: 0.75em;
    outline: none;
}

.existNumber {
    margin: 0;
    font-size: 48px;
    font-weight: 600;
    white-space: nowrap;
}

.footerContainer {
    display: flex;
    width: 100%;
    height: 80px;
    border-top: 1px solid #bcc9e4;
}

.newContainer {
    display: flex;
    width: 50%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #bcc9e4;
}

.pendingContainer {
    display: flex;
    width: 50%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.newNumber {
    margin: 0;
    font-size: 1.75em;

    &.zero {
        color: #008b9c;
    }
}

.pendingNumber {
    margin: 0;
    font-size: 1.75em;
}

.statusText {
    margin: 0;
    color: #303e65;
    font-size: 0.85em;
    font-weight: bold;
}
