.userLocationLabel {
    margin: 0 0 10px;
    color: #008b9c;
    font-size: 14px;
}

.userLocation {
    display: inline-flex;
    min-width: 180px;
    align-items: center;
    justify-content: center;
    padding: 6px;
    border: 1px solid #e5eaf5;
    background-color: #fff;
    border-radius: 10px;
    word-break: break-all;
}

.userLocationIcon {
    width: 15px;
    height: 18px;
}

.userLocationText {
    margin-left: 10px;

    h3 {
        margin: 0;
        font-size: 0.85em;
        font-weight: 500;
    }

    p {
        margin: 0;
        color: #008b9c;
        font-size: 0.65em;
    }
}

.userLocationArrow {
    margin-left: 30px;
}
