.root {
    display: flex;
    width: 100%;
    max-width: 337px;
    flex-direction: column;
}

.require {
    color: #eb5757;
    font-size: 15px;
}

.error,
.inputName {
    margin-bottom: 6px;
    margin-left: 16px;
    color: #303e65;
    font-size: 13px;
    font-weight: 600;
}

.error {
    color: #eb5757;
}

.datePicker {
    :global {
        .react-datepicker {
            font-family: "Work Sans", Arial, sans-serif !important;
            font-size: 14px;
            font-weight: 300;
            line-height: 16px;
        }

        .react-datepicker__close-icon {
            right: 5px;

            &::after {
                width: 20px;
                height: 20px;
                padding: 0;
                background-color: #22404d !important;
                color: #fff !important;
                font-size: 18px;
            }
        }

        .react-datepicker__input-container input {
            display: block;
            width: 100%;
            height: 100%;
            padding: 15px 21px;
            border: 0;
            background-color: #f8faff;
            border-radius: 15px;
            box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.05), inset 0 5px 20px rgba(0, 0, 0, 0.03);
            color: #303e65;
            font-weight: 500;
            outline: none;

            &::placeholder {
                color: #008b9c;
                font-weight: 400;
            }

            &:focus {
                box-shadow: 2px 1px 9px 2px rgba(74, 82, 87, 0.18);
            }

            &:disabled {
                background-color: #e9e9e9;
            }
        }

        .react-datepicker__input-container {
            height: 48px;
            background: #f8faff;
            border-radius: 15px;
            box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.05), inset 0 5px 20px rgba(0, 0, 0, 0.03);
        }
    }
}

.popperStyles {
    :global {
        .react-datepicker {
            border: 0;
            border-radius: 15px;
            box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.05), inset 0 5px 20px rgba(0, 0, 0, 0.03);
            font-family: "Work Sans", Arial, sans-serif !important;
            font-size: 14px;
            font-weight: 300;
            line-height: 16px;
        }

        .react-datepicker__header {
            border: 0;
            background: #f8faff;
            border-radius: 15px;
            box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.05), inset 0 5px 20px rgba(0, 0, 0, 0.03);
        }

        .react-datepicker__year-dropdown {
            border: 0;
            background: #f8faff;
            border-radius: 15px;
            box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.05), inset 0 5px 20px rgba(0, 0, 0, 0.03);
        }

        .react-datepicker__year-dropdown-container {
            margin-left: 20px;
        }

        .react-datepicker__year-option {
            margin-bottom: 2px;

            &:hover {
                background-color: #e5eaf5;
            }
        }

        .react-datepicker__month-dropdown {
            border: 0;
            background: #f8faff;
            border-radius: 15px;
            box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.05), inset 0 5px 20px rgba(0, 0, 0, 0.03);
        }

        .react-datepicker__month-option {
            margin-bottom: 2px;

            &:hover {
                background-color: #e5eaf5;
            }
        }

        .react-datepicker__day--selected {
            background-color: #60b5a0;
        }

        .react-datepicker__day--keyboard-selected {
            background-color: #60b5a0;
        }

        .react-datepicker__current-month {
            color: #303e65;
            font-weight: 500;
        }

        .react-datepicker-time__header {
            color: #303e65;
            font-weight: 500;
        }

        .react-datepicker__time-list::-webkit-scrollbar {
            width: 8px;
        }

        /* Track */
        .react-datepicker__time-list::-webkit-scrollbar-track {
            background: #e5eaf5;
            border-radius: 10px;
        }

        /* Handle */
        .react-datepicker__time-list::-webkit-scrollbar-thumb {
            height: 128px;
            background: #22404d;
            border-radius: 10px;
        }

        /* Handle on hover */
        .react-datepicker__time-list::-webkit-scrollbar-thumb:hover {
            background: #555;
        }

        .react-datepicker__time-list::-webkit-scrollbar-button {
            height: 8%;
        }
    }
}
