@import "../../mixin";

.rootUnauthorized {
    width: 82px;
    background: #285f74;
}

.root {
    width: 282px;
    padding-bottom: 130px;
    background: linear-gradient(to right, #285f74 82px, #22404d 82.1px);

    @include tablet {
        width: 82px;
        background-color: #285f74;
    }
}

.header {
    display: flex;
}

.logo {
    display: flex;
    min-width: 82px;
    min-height: 82px;
    max-height: 82px;
    align-items: center;
    justify-content: center;
    background-color: #eceff7;
    cursor: pointer;
}

.title {
    height: 82px;
    padding: 23px 66px 24px 30px;
    border-bottom: 1px solid  #46b17b;
    margin: 0;
    background: #22404d;
    color: #f2f2f2;
    cursor: default;
    font-family: Poppins, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;

    @include tablet {
        display: none;
    }
}

.navigation {
    margin-top: 19px;
    margin-right: 10px;
    margin-left: 10px;
}

.navItemIcon {
    width: 24px;
    max-width: 24px;
    height: 24px;

    path {
        stroke: #fff;
    }

    circle {
        fill: #fff;
    }
}

.navItem {
    display: flex;
    height: 42px;
    align-items: center;
    padding-right: 11px;
    padding-left: 11px;
    margin-bottom: 5px;
    border-radius: 10px;
    text-decoration: none;

    @include tablet {
        justify-content: center;
        padding-right: 0;
        padding-left: 0;
    }

    &:hover {
        background-color: rgba(#46b17b, 0.5);

        .navItemIcon {
            :global {
                animation: rotate360 0.8s linear;
            }
        }
    }
}

.navItemReports.navItemActive {
    &:hover {
        cursor: pointer;
    }
}

.navSeparator {
    position: relative;
    margin-top: 60px;

    &::before {
        position: absolute;
        top: -20px;
        left: -15px;
        width: 282px;
        height: 1px;
        background-color: #46b17b;
        content: "";

        @include tablet {
            width: 82px;
        }
    }
}

.navItemName {
    max-width: 150px;
    margin-left: 50px;
    color: #fff;
    font-size: 15px;

    @include tablet {
        display: none;
    }
}

.navItemEmail {
    composes: navSeparator;
}

.navItemIconSettings {
    margin-left: auto;

    @include tablet {
        display: none !important;
    }
}

.navItemWrapper {
    position: relative;
}

.navItemStateAdmins {
    composes: navSeparator;
}

.navItemSettings {
    composes: navSeparator;
    margin-top: 80px;

    &::before {
        width: 82px;
        background-color: #58aa97;
    }
}

.navItemSettingsActive {
    background-color: rgba(239, 132, 0, 0.6);
}

.navItemActive {
    border: 1px solid #c3edef;
    background-color: #46b17b;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.05);

    &:hover {
        background-color: #46b17b;
        cursor: default;

        .navItemIcon {
            animation: none;
        }
    }
}

.navItemCount {
    margin-left: auto;

    @include tablet {
        display: none !important;
    }
}

.navProfileContainer {
    position: relative;
    z-index: 2;
    will-change: top;
}

.policyLinkLogIn {
    display: flex;
    width: 150px;
    flex-direction: column;
    margin-left: 84px;
    gap: 5px 0;

    @include tablet {
        width: 77px;
        margin-left: 5px;
        word-break: break-all;
    }

    a {
        padding: 0;
        border: 0;
        color: #fff;
        font-size: 13px;
        text-decoration: none;
    }
}

.navProfile {
    composes: navItem;
    position: relative;
    display: flex;
    width: 250px;
    margin-right: 15px;
    margin-left: 15px;

    @include tablet {
        width: 52px;
    }

    &::before {
        position: absolute;
        top: -20px;
        left: -16px;
        width: 82px;
        height: 1px;
        background-color: #58aa97;
        content: "";
    }
}

.navProfileName {
    composes: navItemName;
    overflow: hidden;
    max-width: 110px;
    margin-left: 47px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.navExit {
    composes: navItem;
    position: relative;
    display: flex;
    width: 250px;
    margin-right: 15px;
    margin-left: 15px;

    @include tablet {
        width: 52px;
    }
}

.navExitMini {
    composes: navExit;
    width: auto;
    margin-top: 20px;
}

.navLogoutIcon {
    composes: navItemIcon;
    margin-left: 8px;
}

.navLogoutName {
    composes: navItemName;
    margin-left: 52px;
}

.btnDropdownLng {
    background-color: #ccc;
}

.selectLanguageOption {
    display: flex;
    padding-left: 8px;
}

.selectLanguageLabel {
    margin-left: 60px;
    color: #fff;
    font-size: 15px;
}

.selectLanguage {
    margin-right: 15px;
    margin-left: 15px;

    :global {

        @include tablet {
            .select__indicator,
            .select__indicators {
                display: none;
            }

            .select__single-value {
                .option-label {
                    display: none;
                }
            }
        }

        .select__option {
            padding: 15px;
            background: none;
            border-radius: 15px;
            color: #008b9c;
            cursor: pointer;

            svg {
                display: none;
            }

            span {
                color: #008b9c;
            }
        }

        .select__control {
            border: 0;
            background-color: transparent;

            &:hover {
                background-color: rgba(239, 132, 0, 0.5);
                cursor: pointer;

                svg {
                    :global {
                        animation: rotate360 0.8s linear;
                    }
                }
            }
        }

        .select__indicator-separator {
            display: none;
        }

        .select__option--is-focused {
            background-color: #f8faff;
        }

        .select__option--is-selected {
            background-color: #e8e8e8;
            border-radius: 0;
        }

        .select__menu {
            position: absolute;
            z-index: 2;
            min-width: 250px;
        }
    }
}

.selectLanguageMini {
    margin-top: 10px;
    border-radius: 10px;

    :global {
        .select__indicator {
            display: none;
        }

        .select__single-value {
            .option-label {
                display: none;
            }
        }
    }
}

.preview {
    img {
        object-fit: cover;
    }
}

.warningParentalConfirmation {
    max-width: 32px;
    height: 32px;
    margin-left: auto;
    background-color: #22404d;
    border-radius: 50%;
    fill: #eb5757;
}
