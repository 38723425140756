@mixin mobile {

    @media (max-width: 768px) {
        @content;
    }
}

@mixin tablet {

    @media (max-width: 1024px) {
        @content;
    }
}

@mixin media-custom($width) {

    @media (max-width: #{$width}px) {
        @content;
    }
}
